import { Trans, useTranslation } from 'react-i18next';
import { namespaces } from '@zenown-insurance/i18n-service';
import {
  Grid,
  Typography,
  Link,
  Divider,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Avatar,
  IconButton,
} from '@mui/material';
import ReturnButton from './../../../../components/return-button/ReturnButton';
import RVLogo from './../../../../assets/insurance/r+v-logo.png';
import CancelIcon from '@mui/icons-material/Cancel';
import { useContext, useState, useEffect, useRef } from 'react';
import Bulb from '../../../../assets/insurance/Bulb.png';
import Bulb2 from '../../../../assets/insurance/Bulb@2x.png';
import Bulb3 from '../../../../assets/insurance/Bulb@3x.png';
import WatchPicture from '../../../../assets/insurance/WatchPicture.png';
import WatchPicture2 from '../../../../assets/insurance/WatchPicture@2x.png';
import WatchPicture3 from '../../../../assets/insurance/WatchPicture@3x.png';
import CameraPlus from '../../../../assets/insurance/CameraPlus.png';
import CameraPlus2 from '../../../../assets/insurance/CameraPlus@2x.png';
import CameraPlus3 from '../../../../assets/insurance/CameraPlus@3x.png';
import HowWatchPicture from '../../../../assets/insurance/HowWatchPicture.png';
import HowWatchPicture2 from '../../../../assets/insurance/HowWatchPicture@2x.png';
import HowWatchPicture3 from '../../../../assets/insurance/HowWatchPicture@3x.png';
import DescriptionIcon from '../../../../assets/insurance/DescriptionIcon.png';
import DescriptionIcon2 from '../../../../assets/insurance/DescriptionIcon@2x.png';
import DescriptionIcon3 from '../../../../assets/insurance/DescriptionIcon@3x.png';
import Cameraa from '../../../../assets/insurance/cameraa.png';
import Cameraa2 from '../../../../assets/insurance/cameraa@2x.png';
import Cameraa3 from '../../../../assets/insurance/cameraa@3x.png';
import Expand from '../../../../assets/insurance/expand.png';
import Expand2 from '../../../../assets/insurance/expand@2x.png';
import Expand3 from '../../../../assets/insurance/expand@3x.png';
import ThumbsDown from '../../../../assets/insurance/Thumsdown.png';
import ThumbsDown2 from '../../../../assets/insurance/Thumsdown@2x.png';
import ThumbsDown3 from '../../../../assets/insurance/Thumsdown@3x.png';
import ThumbsUp from '../../../../assets/insurance/Thumsup.png';
import ThumbsUp2 from '../../../../assets/insurance/Thumsup@2x.png';
import ThumbsUp3 from '../../../../assets/insurance/Thumsup@3x.png';
import SunBright from '../../../../assets/insurance/sunBright.png';
import SunBright2 from '../../../../assets/insurance/sunBright@2x.png';
import SunBright3 from '../../../../assets/insurance/sunBright@3x.png';
import InsureProductProcessContext from '../../../../../lib/contexts/InsureProductProcessContext';
import ApplePayForm from '../../../../../lib/components/zenown-insurance/ApplePayForm';
import StripePayForm from '../../../../../lib/components/zenown-insurance/StripePayForm';
import ErrorMessage from '../../../../../lib/components/error-message/ErrorMessage';
import SerialNumberHow from './SerialNumberHow';
import LegalForm from './LegalForm';
import { LongFooter } from 'libs/ui/src/lib/components/zenown-insurance/LongFooter';
import PrimaryButton from 'libs/ui/src/lib/components/primary-button/PrimaryButton';
import { UploadSection } from 'libs/ui/src/lib/components/zenown-insurance/UploadSection';
import Sheet from 'react-modal-sheet';
import { AllianzInsuranceService } from '@zenown-insurance/services/requests';
import Draggable from 'react-draggable';

export function HowToPictureComponent(props: any) {
  const { tSerial: t, trackEvent } = useContext(InsureProductProcessContext);
  const [isLiked, setIsLiked] = useState<any>(localStorage.getItem('isLiked'));
  const clickLike = (value: any) => {
    localStorage.setItem('isLiked', value);
    setIsLiked(value);
  };

  return (
    <>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'flex-start',
          alignItems: 'stretch',
          textAlign: 'center',
        }}
      >
        <Typography
          variant="h1"
          sx={{
            fontWeight: 900,
            fontSize: '20px',
            lineHeight: '24px',
            marginBottom: '24px',
          }}
        >
          {props.title}
        </Typography>
        {props.image}
        <div
          style={{
            marginBottom: '24px',
            textAlign: 'left',
            display: 'flex',
            flexDirection: 'row',
          }}
        >
          <img
            src={DescriptionIcon}
            srcSet={`${DescriptionIcon} 1x, ${DescriptionIcon2} 2x, ${DescriptionIcon3} 3x`}
            style={{
              width: '18px',
              height: '18px',
              marginRight: '16px',

              verticalAlign: 'middle',
            }}
          />
          <Typography
            variant="caption"
            sx={{
              fontWeight: 500,
              fontSize: '14px',
              lineHeight: '21px',
              color: '#0179FF',
            }}
          >
            {props.description}
          </Typography>
        </div>

        <Divider />
        <ListItem
          sx={{ marginTop: '10px', padding: 0, alignItems: 'flex-start' }}
        >
          <ListItemAvatar
            sx={{
              marginTop: '10px',
              width: '40px',
              height: '40px',
              maxWidth: '40px !important',
              maxHeight: '40px',
              minWidth: '40px !important',
              minHeight: '40px',
              borderRadius: '6px',
              background: '#F7FAFF',
              //opacity: '0.1',
              position: 'relative',
              marginRight: '16px',
            }}
          >
            <img
              src={SunBright}
              srcSet={`${SunBright} 1x, ${SunBright2} 2x, ${SunBright3} 3x`}
              style={{
                width: '24px',
                height: '24px',
                position: 'absolute',
                zIndex: 99,
                left: 0,
                right: 0,
                top: 0,
                bottom: 0,
                margin: 'auto',
              }}
            />
          </ListItemAvatar>
          <ListItemText
            primary={
              <Typography
                variant="caption"
                sx={{
                  fontWeight: 500,
                  fontSize: '18px',
                  lineHeight: '24px',
                  color: '#383A49',
                }}
              >
                {t('pictureUpload.brightness.title')}
              </Typography>
            }
            secondary={
              <Typography
                variant="caption"
                sx={{
                  fontWeight: 500,
                  fontSize: '14px',
                  lineHeight: '21px',
                  color: '#9CA0AB',
                }}
              >
                <br />
                {t('pictureUpload.brightness.description')}
              </Typography>
            }
          />
        </ListItem>
        <ListItem
          sx={{ marginTop: '10px', padding: 0, alignItems: 'flex-start' }}
        >
          <ListItemAvatar
            sx={{
              marginTop: '10px',
              width: '40px',
              height: '40px',
              maxWidth: '40px !important',
              maxHeight: '40px',
              minWidth: '40px !important',
              minHeight: '40px',
              borderRadius: '6px',
              background: '#F7FAFF',
              //  opacity: '0.1',
              position: 'relative',
              marginRight: '16px',
            }}
          >
            <img
              src={Cameraa}
              srcSet={`${Cameraa} 1x, ${Cameraa2} 2x, ${Cameraa3} 3x`}
              style={{
                width: '24px',
                height: '24px',
                position: 'absolute',
                left: 0,
                right: 0,
                top: 0,
                bottom: 0,
                margin: 'auto',
              }}
            />
          </ListItemAvatar>
          <ListItemText
            primary={
              <Typography
                variant="caption"
                sx={{
                  fontWeight: 500,
                  fontSize: '18px',
                  lineHeight: '24px',
                  color: '#383A49',
                }}
              >
                {t('pictureUpload.cameraflash.title')}
              </Typography>
            }
            secondary={
              <Typography
                variant="caption"
                sx={{
                  fontWeight: 500,
                  fontSize: '14px',
                  lineHeight: '21px',
                  color: '#9CA0AB',
                }}
              >
                <br />
                {t('pictureUpload.cameraflash.description')}
              </Typography>
            }
          />
        </ListItem>
        <ListItem
          sx={{ marginTop: '10px', padding: 0, alignItems: 'flex-start' }}
        >
          <ListItemAvatar
            sx={{
              marginTop: '10px',
              width: '40px',
              height: '40px',
              maxWidth: '40px !important',
              maxHeight: '40px',
              minWidth: '40px !important',
              minHeight: '40px',
              borderRadius: '6px',
              background: '#F7FAFF',
              // opacity: '0.1',
              position: 'relative',
              marginRight: '16px',
            }}
          >
            <img
              src={Expand}
              srcSet={`${Expand} 1x, ${Expand2} 2x, ${Expand3} 3x`}
              style={{
                width: '24px',
                height: '24px',
                position: 'absolute',
                left: 0,
                right: 0,
                top: 0,
                bottom: 0,
                margin: 'auto',
              }}
            />
          </ListItemAvatar>
          <ListItemText
            primary={
              <Typography
                variant="caption"
                sx={{
                  fontWeight: 500,
                  fontSize: '18px',
                  lineHeight: '24px',
                  color: '#383A49',
                }}
              >
                {t('pictureUpload.distance.title')}
              </Typography>
            }
            secondary={
              <Typography
                variant="caption"
                sx={{
                  fontWeight: 500,
                  fontSize: '14px',
                  lineHeight: '21px',
                  color: '#9CA0AB',
                }}
              >
                <br />
                {t('pictureUpload.distance.description')}
              </Typography>
            }
          />
        </ListItem>
        <Divider sx={{ marginTop: '20px' }} />
        <div style={{ marginTop: '20px', textAlign: 'left' }}>
          <Typography
            variant="caption"
            sx={{
              fontWeight: 500,
              fontSize: '18px',
              lineHeight: '22px',
            }}
          >
            {t('pictureUpload.helpus')}
          </Typography>
          <div
            style={{
              // height: '105px',
              textAlign: 'center',
              borderRadius: '10px',
              backgroundColor: '#F7FAFF',
              padding: '16px',
              marginTop: '12px',
            }}
          >
            {' '}
            <Typography
              variant="caption"
              sx={{
                fontWeight: 500,
                fontSize: '14px',
                lineHeight: '21px',
              }}
            >
              {t('pictureUpload.helpusdescription')}
            </Typography>
            <br />
            <div style={{ marginTop: '12px' }}>
              <IconButton
                onClick={() => clickLike(-1)}
                sx={{
                  width: '40px',
                  height: '40px',
                  borderRadius: '50%',
                  backgroundColor:
                    isLiked + '' !== -1 + '' ? 'white' : '#F7FAFF',
                }}
              >
                <img
                  src={ThumbsDown}
                  srcSet={`${ThumbsDown} 1x, ${ThumbsDown2} 2x, ${ThumbsDown3} 3x`}
                  style={{
                    width: '17px',
                    height: '17px',
                  }}
                />
              </IconButton>
              <IconButton
                onClick={() => clickLike(1)}
                sx={{
                  width: '40px',
                  height: '40px',
                  borderRadius: '50%',
                  backgroundColor:
                    isLiked + '' !== 1 + '' ? 'white' : '#F7FAFF',
                }}
              >
                <img
                  src={ThumbsUp}
                  srcSet={`${ThumbsUp} 1x, ${ThumbsUp2} 2x, ${ThumbsUp3} 3x`}
                  style={{
                    width: '17px',
                    height: '17px',
                  }}
                />
              </IconButton>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
const embeddedInsuranceService = new AllianzInsuranceService();
export function PictureUpload() {
  const [opacity, setOpacity] = useState(1);

  const handleDrag = (e: any, ui: any) => {
    const { y } = ui;
    const windowHeight = window.innerHeight;
    // This logic assumes that the opacity will decrease linearly
    // with the vertical distance dragged, and the message box will
    // completely fade away when it is dragged to the bottom of the screen.
    const newOpacity = 1 - Math.min(Math.abs(y) / 68, 1);
    setOpacity(newOpacity);
  };
  const {
    tSerial: t,
    receipt,
    currentProcessStep,
    setCurrentProcessStep,
    errorMessage,
    setErrorMessage,
    apiErrorMessage,
    setApiErrorMessage,
    serialAccepted,
    setPayementCancelDialog,
    setWatchPicture,
    watchPicture,
    setIsLoading,
    isLoading,
    setProgress,
    constraintImage,
    tackEvent,
    progress,
    trackEvent,
  } = useContext(InsureProductProcessContext);
  useEffect(() => {
    trackEvent({
      event: 'Page Visit',
      action: 'Take a photo of your watch',
    });
  }, []);
  const [err, setErr] = useState<any>();

  const { t: serialT } = useTranslation(namespaces.pages.serialNumberPage);
  const [showHowSheet, setShowHowsheet] = useState(false);
  const onUpload = async () => {
    let e = watchPicture;
    try {
      trackEvent({
        event: 'Button Click',
        action: 'Submit',
      });
      setErrorMessage(undefined);
      setIsLoading(true);
      const { data: receiptData } =
        await embeddedInsuranceService.getPictureUploadUrl();
      let { uid, uploadURL } = receiptData;
      if (!uid || !uploadURL) throw 'failed fetching upload url ';
      const newFile = await constraintImage(e, 100, 1);
      let binaryFile: any;
      await fetch(newFile[0].data_url).then(
        async (res) => (binaryFile = await res.blob())
      );
      const options = {
        onUploadProgress: (progressEvent: any) => {
          const { loaded, total } = progressEvent;
          let percent = Math.floor((loaded * 100) / total);

          setProgress(percent);
        },
      };
      await embeddedInsuranceService.uploadFile(
        uploadURL + '',
        binaryFile,
        options
      );
      const notification: any =
        await embeddedInsuranceService.notifyWithThePhotoUpload(
          receiptData?.uid,
          0,
          receipt.uid
        );
      setCurrentProcessStep('watchserialupload');
      setErr(undefined);
    } catch (err) {
      setErr('pictureUpload.watch.uploadError');
    } finally {
      setIsLoading(false);
      setProgress(undefined);
    }
  };

  return (
    <div style={{ position: 'relative' }}>
      {Boolean(errorMessage) && (
        <Draggable
          onDrag={handleDrag}
          axis="y"
          onStop={() => {
            if (opacity < 0.2) setErrorMessage(undefined);
          }}
        >
          <div
            onMouseUp={() => {
              setErrorMessage(undefined);
            }}
            style={{
              position: 'absolute',
              backgroundColor: '#FF2D2D',
              boxShadow: '0px 2px 8px rgba(0, 0, 0, 0.1)',
              borderRadius: '10px',
              padding: '15px',
              display: 'flex',
              flexDirection: 'row',
              gap: '14px',
              zIndex: '9999',
              opacity: opacity,
            }}
          >
            <IconButton
              onClick={() => {
                setErrorMessage(undefined);
              }}
            >
              <CancelIcon
                sx={{ color: 'white', height: '21px', width: '21px' }}
              />
            </IconButton>

            <Typography
              sx={{
                color: 'white',
                fontWeight: '500',
                fontSize: '14px',
                lineHeight: '19px',
              }}
            >
              {errorMessage}
            </Typography>
          </div>
        </Draggable>
      )}
      <Grid
        container
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        sx={{ marginTop: '20px' }}
      >
        <Grid item>
          <ReturnButton
            style={{ position: 'relative' }}
            onClick={() => {
              setCurrentProcessStep('upload');
            }}
          />
        </Grid>
      </Grid>

      <div
        style={{
          marginTop: '33px',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'start',
          alignItems: 'stretch',
        }}
      >
        <Typography variant="h1">{t('pictureUpload.watch.title')}</Typography>
        <Typography
          variant="subtitle1"
          sx={{ marginTop: '16px', textAlign: 'left' }}
        >
          <Trans
            i18nKey="pictureUpload.watch.description"
            t={t}
            components={{
              strong: <strong>{t('pictureUpload.watch.description')}</strong>,
              underline: (
                <span style={{ textDecoration: 'underline' }}>
                  {t('pictureUpload.watch.description')}
                </span>
              ),
            }}
          ></Trans>
        </Typography>
      </div>
      <div
        style={{
          marginTop: '33px',
          height: '226.14px',
          position: 'relative',
        }}
      >
        <img
          src={WatchPicture}
          srcSet={`${WatchPicture} 1x, ${WatchPicture2} 2x, ${WatchPicture3} 3x`}
          style={{
            width: '132.92px',
            height: '226.14px',
            position: 'absolute',
            marginInline: 'auto',
            left: 0,
            right: 0,
          }}
        />
      </div>
      <div
        style={{
          marginTop: '64px',
          display: 'flex',
          flexDirection: 'row',
          gap: '8px',
          alignItems: 'center',
        }}
      >
        <img
          src={Bulb}
          srcSet={`${Bulb} 1x, ${Bulb2} 2x, ${Bulb3} 3x`}
          style={{
            width: '36px',
            height: '36px',
          }}
        />

        <Typography
          variant="subtitle1"
          sx={{
            fontWeight: '500',
            fontSize: '12px',
            lineHeight: '22px',

            color: '#0179FF',
          }}
        >
          <Link
            underline="none"
            onClick={() => {
              let v = !showHowSheet;
              if (v) {
                trackEvent({
                  event: 'Landing Page',
                  action: "Here's How",
                });
              }
              setShowHowsheet(v);
            }}
          >
            <Trans
              i18nKey="pictureUpload.watch.hint"
              t={t}
              components={{
                underline: (
                  <span style={{ textDecoration: 'underline' }}>
                    {t('pictureUpload.watch.hint')}
                  </span>
                ),
              }}
            ></Trans>
          </Link>
        </Typography>
      </div>
      <div
        key={watchPicture?.length}
        style={{
          marginTop: '23px',
          position: 'relative',
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'stretch',
          justifyContent: 'center',
        }}
      >
        <UploadSection
          key={watchPicture?.length}
          text={t('pictureUpload.watch.uploadPhrase')}
          logo={
            <img
              src={CameraPlus}
              srcSet={`${CameraPlus} 1x, ${CameraPlus2} 2x, ${CameraPlus3} 3x`}
              style={{ width: '29px', height: '28px' }}
            />
          }
          onChange={async (e: any) => {
            if (e) {
              trackEvent({
                event: 'Button Click',
                action: 'Upload a photo of your watch',
              });
            }

            setErrorMessage(undefined);
            setWatchPicture(e);
          }}
          files={watchPicture}
        />
      </div>
      <div
        style={{
          marginTop: '23px',
        }}
      >
        {err ? (
          <div
            style={{
              marginTop: '8px',
            }}
          >
            <ErrorMessage message={err} />
          </div>
        ) : null}
        <PrimaryButton
          loadingLogic={true}
          disabled={Boolean(!watchPicture || !watchPicture.length)}
          onClick={async () => {
            onUpload();
            //setCurrentProcessStep('watchserialupload');
          }}
          sx={{
            fontStyle: 'Semibold S',
            marginTop: '20px',
            marginBottom: '20px',
          }}
        >
          {t('pictureUpload.watch.submitPicture')}
        </PrimaryButton>
      </div>
      <Sheet
        style={{ maxWidth: '500px', margin: '0 auto', zIndex: 999 }}
        isOpen={showHowSheet}
        disableDrag={false}
        onClose={() => {
          setShowHowsheet(false);
        }}
      >
        <Sheet.Container style={{}}>
          <Sheet.Header style={{}} />
          <Sheet.Content style={{ padding: '16px' }} disableDrag={true}>
            <div
              style={{ display: 'inline-block', position: 'relative' }}
              onScroll={(e: any) => {
                console.log('e', e);
              }}
              onDrag={(e: any) => {
                console.log('e', e);
              }}
            >
              <HowToPictureComponent
                {...{
                  title: t('pictureUpload.watch.how.title'),
                  description: t('pictureUpload.watch.how.description'),
                  image: (
                    <img
                      src={HowWatchPicture}
                      srcSet={`${HowWatchPicture} 1x, ${HowWatchPicture2} 2x, ${HowWatchPicture3} 3x`}
                      style={{
                        width: '100%',
                        marginBottom: '15px',
                      }}
                    />
                  ),
                }}
              />
            </div>
          </Sheet.Content>
        </Sheet.Container>

        <Sheet.Backdrop />
      </Sheet>
    </div>
  );
}

export default PictureUpload;
