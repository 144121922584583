import { StrictMode, Suspense } from 'react';
import * as ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import App from './app/app';
import * as Sentry from '@sentry/react';

console.log(process.env['NX_INSURANCE_API_URL']);
Sentry.init({
  dsn:
    process.env['NX_SENTRY_ID'] ||
    'https://3d92acc07ee549c1a6734b882850eb01@o1210764.ingest.sentry.io/4505239499177984',
  integrations: [new Sentry.BrowserTracing(), new Sentry.Replay()],
  // Performance Monitoring
  tracesSampleRate: 1.0, // Capture 100% of the transactions, reduce in production!
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

ReactDOM.render(
  <Suspense fallback={<></>}>
    <StrictMode>
      <BrowserRouter>
        {' '}
        <App />
      </BrowserRouter>
    </StrictMode>
  </Suspense>,
  document.getElementById('root')
);
