import { makeStyles } from '@mui/styles';
import {
  Grid,
  Typography,
  TextField,
  Autocomplete,
  Box,
  ListItem,
  ListItemText,
  ListItemIcon,
  Checkbox,
  Radio,
  SvgIcon,
} from '@mui/material';
import { Controller, useForm } from 'react-hook-form';
import PrimaryButton from '../primary-button/PrimaryButton';
import { useContext, useEffect, useState, createRef } from 'react';
import InsureProductProcessContext from '../../contexts/InsureProductProcessContext';
import { UserInfo } from '@zenown-insurance/services/requests';
import AppleIcon from './../../assets/insurance/apple-icon.svg';
import { DatePicker } from '@mui/x-date-pickers';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { namespaces } from '@zenown-insurance/i18n-service';
import './datePickerStyles.css';
import { ReactComponent as CheckedRoundedCheckbox } from '../../assets/insurance/checked-rounded-checkbox.svg';

import BlueUnCheckBoxIcon from '../../assets/insurance/blueUnCheckBoxIcon.svg';
import BlueCheckBoxIcon from '../../assets/insurance/blueCheckBoxIcon.svg';
const IconWithoutPremium = ({ error }: any) => {
  return (
    <SvgIcon
      component={() => (
        <div
          style={{
            width: '24px',
            height: '24px',
            maxWidth: '24px',
            maxHeight: '24px',
            background: '#FFFFFF',
            borderRadius: '50%',
            border: error ? '1px solid red' : '1px solid #DFE0E5',
            margin: '0px',
            marginRight: '15px',
          }}
        ></div>
      )}
      inheritViewBox
      style={{
        marginRight: '15px',
        width: '24px',
        height: '24px',
        maxWidth: '24px',
        maxHeight: '24px',
        color: '#FFFFFF',
        border: 'none',
      }}
    />
  );
};

const IconWithPremium = () => {
  return (
    <SvgIcon
      component={CheckedRoundedCheckbox}
      inheritViewBox
      style={{
        marginRight: '15px',
        width: '24px',
        maxWidth: '24px',
        maxHeight: '24px',
        height: '24px',
        border: 'none',
      }}
    />
  );
};
export const ApplePayForm = () => {
  const {
    registerUser,
    setErrorMessage,
    errorMessage,
    tcommon,
    tUserForm: t,
    apiErrorMessage,
    setApiErrorMessage,
    userInfo,
    setIsLoading,
    trackEvent,
  } = useContext(InsureProductProcessContext);

  const {
    handleSubmit,
    control,
    getValues,
    watch,
    formState,
    clearErrors,
    setError,
    setValue,
  } = useForm({
    shouldFocusError: true,
    reValidateMode: 'onBlur',
    criteriaMode: 'firstError',
    mode: 'onBlur',
    defaultValues: { ...userInfo, country: 'Switzerland' },
  });
  const [dateOpen, setDateOpen] = useState(false);
  const [accept, setAccept] = useState<boolean>(
    Boolean(localStorage.getItem('acceptTerms') || true)
  );

  const fields = [
    {
      id: 'firstName',
      label: t('form.firstName.label'),
      type: 'text',
      rules: {
        required: {
          value: true,
          message: t('form.firstName.errorRequired'),
        },
        minLength: {
          value: 3,
          message: t('form.firstName.errorInvalid'),
        },
      },
    },
    {
      id: 'lastName',
      label: t('form.lastName.label'),
      type: 'text',
      rules: {
        required: {
          value: true,
          message: t('form.lastName.errorRequired'),
        },
        minLength: {
          value: 3,
          message: t('form.lastName.errorInvalid'),
        },
      },
    },
    {
      id: 'gender',
      label: t('form.gender.label'),
      type: 'text',
      rules: {
        required: {
          value: true,
          message: t('form.gender.errorRequired'),
        },
      },
      rendering: () => (
        <>
          <Controller
            name={'gender'}
            control={control}
            rules={{
              required: {
                value: true,
                message: t('form.dateOfBirth.errorRequired'),
              },
            }}
            render={({
              field: { onChange, value, onBlur },
              fieldState: { error },
            }) => {
              return (
                <Grid
                  container
                  justifyContent={'space-between'}
                  sx={{ maxHeight: '64.54px !important' }}
                >
                  {['male', 'female'].map((genderValue, i) => (
                    <Grid
                      onClick={() => {
                        onChange({ target: { value: genderValue } });
                        trackEvent({
                          event: 'Button Click',
                          action: genderValue,
                        });
                      }}
                      item
                      xs={5.9}
                      key={i}
                      sx={{
                        padding: '10.68px',
                        paddingInline: '8px',
                        borderRadius: '10px',
                        maxHeight: '64.54px !important',
                        ...(error ? { color: 'red' } : {}),

                        border:
                          getValues()['gender'] === genderValue
                            ? '1px solid #0179FF'
                            : error
                            ? '1px solid red'
                            : '1px solid #EDEDED',
                      }}
                    >
                      {' '}
                      <Radio
                        color={true ? 'error' : undefined}
                        style={{ display: 'inline-block' }}
                        icon={
                          getValues()['gender'] === genderValue ? (
                            <IconWithPremium />
                          ) : (
                            <IconWithoutPremium error={error} />
                          )
                        }
                        checkedIcon={
                          getValues()['gender'] === genderValue ? (
                            <IconWithPremium />
                          ) : (
                            <IconWithoutPremium error={error} />
                          )
                        }
                        checked={getValues()['gender'] === genderValue}
                        onChange={(e: any) => {
                          trackEvent({
                            event: 'Button Click',
                            action: genderValue,
                          });
                          onChange(e);
                        }}
                        value={genderValue}
                      />
                      <div
                        style={{
                          display: 'inline-block',
                          whiteSpace: 'nowrap',
                        }}
                      >
                        <Typography
                          variant="caption"
                          sx={
                            getValues()['gender'] !== genderValue
                              ? {
                                  color: '#9CA0AB',
                                  fontWeight: 500,
                                  fontSize: '17px',
                                  lineHeight: '23px',
                                }
                              : {
                                  color: '#383A49',
                                  fontWeight: 500,
                                  fontSize: '17px',
                                  lineHeight: '23px',
                                }
                          }
                        >
                          {t('form.gender.options.' + genderValue)}
                        </Typography>
                      </div>
                    </Grid>
                  ))}
                </Grid>
              );
            }}
          />
        </>
      ),
    },
    {
      id: 'email',
      label: t('form.email.label'),
      type: 'email',
      rules: {
        required: {
          value: true,
          message: t('form.email.errorRequired'),
        },
        pattern: {
          value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
          message: t('form.email.errorInvalid'),
        },
      },
    },
    {
      id: 'dob',
      label: t('form.dateOfBirth.label'),
      type: 'date',
      rules: {
        required: {
          value: true,
          message: t('form.dateOfBirth.errorRequired'),
        },
      },
      rendering: () => (
        <>
          <Controller
            name={'dob'}
            control={control}
            rules={{
              required: {
                value: true,
                message: t('form.dateOfBirth.errorRequired'),
              },
            }}
            render={({
              field: { onChange, value, onBlur },
              fieldState: { error },
            }) => {
              return (
                <DatePicker
                  className="custom-date-picker"
                  disableFuture
                  DialogProps={{ id: 'custom-date-picker' }}
                  reduceAnimations={true}
                  inputFormat="dd MMM yyyy"
                  label={t('form.dateOfBirth.label')}
                  maxDate={moment().subtract(18, 'years').toDate()}
                  minDate={moment().subtract(100, 'years').toDate()}
                  openTo="year"
                  open={dateOpen}
                  value={value ? value : null}
                  onOpen={() => {
                    clearErrors('dob');
                    if (!errorMessage?.error) setErrorMessage('');
                  }}
                  onClose={() => setDateOpen(false)}
                  onChange={(e: any) => {
                    if (error && !dateOpen) {
                      setError('dob', error);
                      setErrorMessage(error?.message + '');
                    } else {
                      if (!errorMessage) {
                        clearErrors('dob');
                        setErrorMessage('');
                        clearErrors('dob');
                      }
                    }
                    if (typeof e !== 'string')
                      e = moment(e).format('YYYY-MM-DD');
                    clearErrors('dob');
                    onChange(e);
                  }}
                  onAccept={(e: any) => {
                    if (error && !dateOpen) {
                      setError('dob', error);
                      setErrorMessage(error?.message + '');
                    } else {
                      if (!errorMessage) {
                        clearErrors('dob');
                        setErrorMessage('');
                      }
                    }
                    if (typeof e !== 'string')
                      e = moment(e).format('YYYY-MM-DD');
                    clearErrors('dob');
                    onBlur();
                  }}
                  InputProps={{
                    tabIndex: 4,
                    onFocus: (e: any) => {
                      if (getValues('root.dob')?.length) {
                        setDateOpen(true);
                      }
                    },
                  }}
                  renderInput={({ inputRef, inputProps, InputProps }) => (
                    <Box
                      onClick={() => setDateOpen(true)}
                      sx={{
                        display: 'relative',
                        alignItems: 'center',
                        width: '100%',
                        button: {
                          position: 'absolute',
                          right: '15px',
                          marginBottom: '60px',
                        },
                      }}
                    >
                      <TextField
                        onClick={() => setDateOpen(true)}
                        placeholder={t('form.dateOfBirth.label')}
                        sx={{ width: '100%' }}
                        label={t('form.dateOfBirth.label')}
                        ref={inputRef}
                        inputProps={{
                          ...inputProps,
                          tabIndex: 4,
                          enterkeyhint: 'Next',
                          autofocus: true,
                          onFocus: (e: any) => {
                            console.log(getValues()?.dob);
                            if (!getValues()?.dob?.length) {
                              setDateOpen(true);
                            }
                          },
                        }}
                        error={Boolean(error)}
                        onKeyDown={(e: any) => {
                          e.preventDefault();
                        }}
                        onChange={(e: any) => {
                          if (error && !dateOpen) {
                            setError('dob', error);
                            setErrorMessage(error?.message + '');
                          } else {
                            if (!errorMessage) {
                              clearErrors('dob');
                              setErrorMessage('');
                            }
                          }
                          trackEvent({ event: 'Input', action: 'dob' });
                          onChange(e);
                        }}
                        onBlur={() => {
                          if (getValues()['dob']) clearErrors('dob');
                          trackEvent({ event: 'Input', action: 'dob' });
                          //onBlur();
                        }}
                      />
                      {InputProps?.endAdornment}
                    </Box>
                  )}
                />
              );
            }}
          />
        </>
      ),
    },
    {
      id: 'country',
      label: t('form.country.label'),
      type: 'text',
      rules: {
        required: {
          value: true,
          message: t('form.country.errorRequired'),
        },
      },
    },
    {
      id: 'state',
      label: t('form.state.label'),
      type: 'text',
      rules: {
        required: {
          value: true,
          message: t('form.state.errorRequired'),
        },
      },
    },
    {
      id: 'city',
      label: t('form.city.label'),
      type: 'text',
      rules: {
        required: {
          value: true,
          message: t('form.city.errorRequired'),
        },
      },
    },
    {
      id: 'postal_code',
      label: t('form.postal_code.label'),
      type: 'text',
      rules: {
        required: {
          value: true,
          message: t('form.postal_code.errorRequired'),
        },
      },
    },
    {
      id: 'address',
      label: t('form.address.label'),
      type: 'text',
      rules: {
        required: {
          value: true,
          message: t('form.address.errorRequired'),
        },
        minLength: {
          value: 3,
          message: t('form.address.errorInvalid'),
        },
      },
    },
  ];

  const RenderInput = (fieldKey: string, order: any) => {
    const field: any = fields.find((k) => k.id === fieldKey);
    return (
      <>
        {field.rendering ? (
          field.rendering()
        ) : (
          <Controller
            name={field.id}
            control={control}
            rules={field.rules}
            render={({
              field: { onChange, value, onBlur },
              fieldState: { error },
            }) => (
              <TextField
                defaultValue={''}
                error={Boolean(error)}
                label={field.label}
                InputProps={{
                  ...(field.id === 'email' ? { type: 'email' } : {}),
                  tabIndex: field.id === 'country' ? -1 : order,
                  style: {
                    textTransform:
                      field.id === 'email' ? 'lowercase' : 'capitalize',
                  },
                }}
                {...(field.id === 'email' ? { type: 'email' } : {})}
                inputProps={{
                  tabIndex: field.id === 'country' ? -1 : order,
                  autofocus: true,

                  style: {
                    textTransform:
                      field.id === 'email' ? 'lowercase' : 'capitalize',
                  },
                }}
                fullWidth
                onChange={(e: any) => {
                  if (field.id === 'country') return;
                  if (error) {
                    setError(field.id, error);
                    setErrorMessage(error?.message + '');
                  } else {
                    if (!errorMessage) {
                      clearErrors(field.id);
                      setErrorMessage('');
                    }
                  }
                  onChange(e);
                }}
                onKeyUp={(e: any) => {
                  if (field.id === 'city') {
                    e.preventDefault();

                    // e.target.blur();
                  }
                }}
                onBlur={(e: any) => {
                  if (getValues()[field?.id]) clearErrors(field.id);
                  if (field.id === 'city') {
                    if (document?.activeElement) {
                      setTimeout(() => {
                        //@ts-ignore
                        document?.activeElement?.blur();
                      }, 0);
                      //@ts-ignore
                      document?.activeElement?.blur();
                    }
                    onBlur();
                  }
                  trackEvent({ event: 'Input', action: field.id });
                  //onBlur();
                }}
                value={field.id === 'country' ? 'Switzerland' : value}
              />
            )}
          />
        )}
      </>
    );
  };
  useEffect(() => {
    watch();
  }, [errorMessage]);

  useEffect(() => {
    trackEvent({ event: 'Page Visit', action: 'Enter Your Details' });
    if (!apiErrorMessage) return;
    let value: any = undefined;

    setErrorMessage();
    console.log('value', value);
    console.log('apiErrorMessage', apiErrorMessage);
    if ((apiErrorMessage + '')?.includes('email')) {
      setValue('email', undefined);
      setError(
        'email',
        {
          type: 'custom',
          message: t('form.email.errorInvalid'),
        },
        { shouldFocus: true }
      );

      value = t('form.email.errorInvalid');

      console.log('email ', value);
    }
    if ((apiErrorMessage + '')?.includes('address')) {
      setValue('address', undefined);
      setError(
        'address',
        {
          type: 'custom',
          message: t('form.address.errorInvalid'),
        },
        { shouldFocus: true }
      );

      value = (value ? value + ', ' : '') + t('form.address.errorInvalid');

      console.log('address ', value);
    }
    if ((apiErrorMessage + '')?.includes('first')) {
      setValue('firstName', undefined);
      setError(
        'firstName',
        {
          type: 'custom',
          message: t('form.firstName.errorInvalid'),
        },
        { shouldFocus: true }
      );

      value = (value ? value + ', ' : '') + t('form.firstName.errorInvalid');

      console.log('firstName ', value);
    }
    if ((apiErrorMessage + '')?.includes('last')) {
      setValue('lastName', undefined);
      setError(
        'lastName',
        {
          type: 'custom',
          message: t('form.lastName.errorInvalid'),
        },
        { shouldFocus: true }
      );

      value = (value ? value + ', ' : '') + t('form.lastName.errorInvalid');

      console.log('lastname ', value);
    }
    if (!value?.length && apiErrorMessage?.length) {
      value = t('form.generalError');
    }
    setTimeout(() => {
      setApiErrorMessage(value);
    }, 0);
    setApiErrorMessage(value);
  }, []);
  useEffect(() => {
    if (Object.keys(formState.errors).length) {
      const error: any =
        //@ts-ignore
        formState?.errors[
          Object.keys(formState?.errors)[
            Object.keys(formState?.errors).length - 1
          ]
        ];
      setErrorMessage(error.message);
    } else if (!errorMessage?.error && !errorMessage?.includes('Oops')) {
      setErrorMessage('');
    }
  }, [formState]);
  useEffect(() => {
    if (dateOpen)
      setTimeout(() => {
        clearErrors('dob');
      }, 0);
  }, [dateOpen]);

  return (
    <Grid
      container
      direction="column"
      justifyContent="space-around"
      alignItems="stretch"
    >
      <form
        onSubmit={handleSubmit(async (data: any) => {
          setIsLoading(true);
          setTimeout(async () => {
            await registerUser(
              { ...data, sex: data.gender === 'male' ? 0 : 1 } as UserInfo,
              formState?.isDirty
            );
            setIsLoading(false);
          }, 500);
        })}
      >
        <Grid item sx={{ marginBottom: '20px' }}>
          {RenderInput('firstName', 1)}
        </Grid>
        <Grid item sx={{ marginBottom: '20px' }}>
          {RenderInput('lastName', 2)}
        </Grid>
        <Grid item sx={{ marginBottom: '20px' }}>
          {RenderInput('gender', 3)}
        </Grid>
        <Grid item sx={{ marginBottom: '20px' }}>
          {RenderInput('email', 4)}
        </Grid>
        <Grid item sx={{ marginBottom: '20px' }}>
          {RenderInput('dob', 5)}
        </Grid>

        <Grid item sx={{ marginBottom: '20px' }}>
          {RenderInput('address', 6)}
        </Grid>
        <Grid
          item
          container
          spacing={2}
          columns={16}
          sx={{ marginBottom: '20px' }}
        >
          <Grid item xs={8}>
            {RenderInput('postal_code', 7)}
          </Grid>
          <Grid item xs={8}>
            {RenderInput('city', 8)}
          </Grid>
        </Grid>
        <Grid item sx={{ marginBottom: '20px' }}>
          {RenderInput('country', undefined)}
        </Grid>
        {false && (
          <ListItem
            disablePadding
            alignItems="flex-start"
            onClick={() => {
              let value = Boolean(!accept);
              setAccept(value);
              localStorage.setItem('acceptTerms', value + '');
            }}
            sx={{ maxWidth: '100%', marginTop: '24px', gap: '0px' }}
          >
            <ListItemIcon
              sx={{
                maxWidth: '32px',
                minWidth: '32px !important',
                maxHeight: '24px',
                padding: 0,
              }}
            >
              <Checkbox
                inputProps={{
                  tabIndex: 0,
                }}
                onClick={() => {
                  let value = Boolean(!accept);
                  setAccept(value);
                  localStorage.setItem('acceptTerms', value + '');
                }}
                checkedIcon={
                  <img
                    style={{ width: '24px', height: '24px' }}
                    src={BlueCheckBoxIcon}
                    alt="Checked icon"
                  />
                }
                icon={
                  <img
                    style={{ width: '24px', height: '24px' }}
                    src={BlueUnCheckBoxIcon}
                    alt="Unchecked icon"
                  />
                }
                sx={{
                  verticalAlign: 'center',
                  width: '24px',
                  height: '24px',
                }}
                checked={Boolean(accept)}
              />
            </ListItemIcon>
            <ListItemText sx={{ minWidth: 'calc(100% - 32px);' }}>
              {' '}
              <Typography
                variant="caption"
                sx={{ fontWeight: '500', fontSize: '12px', lineHeight: '18px' }}
              >
                {t('form.agreementCheck')}
              </Typography>
            </ListItemText>
          </ListItem>
        )}
        <PrimaryButton
          loadingLogic={true}
          disabled={
            Boolean(Object.keys(formState.errors).length > 0) || !accept
          }
          tabIndex={
            Boolean(Object.keys(formState.errors).length > 0) || !accept
              ? -1
              : 8
          }
          sx={{ marginTop: '42px', marginBottom: '30px' }}
          type={
            Boolean(Object.keys(formState.errors).length > 0) || !accept
              ? 'button'
              : 'submit'
          }
          onClick={() => {
            trackEvent({ event: 'Button click', action: 'Continue' });
          }}
        >
          {t('continue')}
        </PrimaryButton>
      </form>
    </Grid>
  );
};

export default ApplePayForm;
