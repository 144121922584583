import { useContext, useEffect, useRef, useState } from 'react';
import Sheet from 'react-modal-sheet';
import InsureProductProcessContext from '../../contexts/InsureProductProcessContext';
import HandShake from '../../assets/insurance/HandShake.png';
import HandShake2 from '../../assets/insurance/HandShake@2x.png';
import HandShake3 from '../../assets/insurance/HandShake@3x.png';
import SerialSuccess from '../../assets/insurance/serial-success.png';
import SerialSuccess2 from '../../assets/insurance/serial-success@2x.png';
import SerialSuccess3 from '../../assets/insurance/serial-success@3x.png';
import {
  Button,
  Checkbox,
  Grid,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
  DialogContent,
  Dialog,
  TextField,
} from '@mui/material';
import PrimaryButton from '../primary-button/PrimaryButton';
import { AllianzInsuranceService } from '@zenown-insurance/services/requests';
export default function SupportSheet({ open, setOpen }: any) {
  const [step, setStep] = useState(0);
  const [data, setData] = useState<any>({ phonenumber: '+49 ', firstname: '' });
  const {
    setFile,
    setIsLoading,
    currentProcessStep,
    setCurrentProcessStep,
    showHowItWorks,
    setShowPolicy,
    submitFile,
    file,
    progress,
    uploadFile,
    errorMessage,
    tUpload: t,
    setErrorMessage,
    trackEvent,
    tcommon,
  } = useContext(InsureProductProcessContext);
  const steps = [
    {
      sheetHeight: '400px',
      img: (
        <img
          id="bikeImage"
          src={HandShake}
          srcSet={`${HandShake} 1x, ${HandShake2} 2x, ${HandShake3} 3x`}
          style={{
            width: '94px',
            height: '94px',
          }}
        />
      ),
      title: t('support.title'),
      subTitle: t('support.subTitle'),
      buttonText: t('support.assistMe'),
      buttonClick: () => {
        setStep(1);
      },
      close: t('close'),
    },
    {
      sheetHeight: '337px',
      title: t('support.name.title'),
      textField: (
        <TextField
          sx={{ minWidth: '250px', width: '100%' }}
          label={t('support.firstname')}
          fullWidth
          value={data.firstname}
          onChange={(event: any) => {
            setData({ ...data, firstname: event.target.value });
          }}
        />
      ),
      disabled: data.firstname.length === 0,
      buttonText: t('support.continue'),
      buttonClick: () => {
        setStep(2);
      },
      close: t('cancel'),
    },
    {
      sheetHeight: '375px',
      title: t('support.phonenumber.title'),
      subTitle: t('support.phonenumber.subTitle'),
      disabled: data.phonenumber.length === 0,
      textField: (
        <TextField
          sx={{ minWidth: '250px', width: '100%' }}
          label={t('support.phonenumber.label')}
          fullWidth
          value={data.phonenumber}
          onChange={(event: any) => {
            setData({ ...data, phonenumber: event.target.value });
          }}
        />
      ),
      buttonClick: async () => {
        try {
          setIsLoading(true);
          const embeddedInsuranceService = new AllianzInsuranceService();
          /* await embeddedInsuranceService.notifyPhoneNumber(
            data.phonenumber,
            data.firstname
          );*/
        } catch (err) {
        } finally {
          setIsLoading(false);
          setStep(3);
        }
      },
      buttonText: t('submit'),
      close: t('cancel'),
    },
    {
      sheetHeight: '337px',
      img: (
        <img
          src={SerialSuccess}
          srcSet={`${SerialSuccess} 1x, ${SerialSuccess2} 2x, ${SerialSuccess3} 3x`}
          style={{
            width: '72.45px',
            height: '72.45px',
          }}
          alt=""
        />
      ),
      title: t('support.success.title'),
      subTitle: t('support.success.subTitle'),
      buttonClick: () => {
        setOpen(false);
        setStep(0);
      },
      buttonText: t('done'),
    },
  ];
  return (
    <>
      <Sheet
        style={{ maxWidth: '500px', margin: '0 auto' }}
        isOpen={open}
        onClose={() => {
          setOpen(false);
        }}
      >
        <Sheet.Container
          style={{
            maxHeight: steps[step].sheetHeight,
            //padding: '16px',
            //paddingInline: '16px',

            //maxWidth: Math.min(500, width) + 'px !important',
          }}
        >
          <Sheet.Header style={{ height: '25px' }} />
          <Sheet.Content style={{ padding: '16px' }}>
            <Grid
              container
              direction="column"
              justifyContent="space-between"
              alignItems="center"
              sx={{
                marginTop: '30px',
                paddingInline: '15px',
                //marginBottom: '20px',
                width: '100%',
              }}
            >
              {Boolean(steps[step].img) && <Grid item>{steps[step].img}</Grid>}

              {Boolean(steps[step].title) && (
                <Grid item sx={{ paddingBottom: '15px', marginTop: '20px' }}>
                  <Typography
                    variant="h3"
                    sx={{
                      fontWeight: '600',
                      display: 'flex',
                      justifyContent: 'center',
                      flexDirection: 'column',
                      textAlign: 'center',
                    }}
                  >
                    {steps[step].title}
                  </Typography>
                </Grid>
              )}
              {Boolean(steps[step].subTitle) && (
                <Grid item sx={{ marginBottom: '15px' }}>
                  <Typography
                    variant="caption"
                    color="#9CA0AB"
                    sx={{
                      fontWeight: '500',
                      display: 'flex',
                      justifyContent: 'center',
                      flexDirection: 'column',
                      textAlign: 'center',
                    }}
                  >
                    {steps[step].subTitle}
                  </Typography>
                </Grid>
              )}
              {Boolean(steps[step].textField) && (
                <Grid
                  item
                  sx={{ marginBottom: '15px', width: '100%' }}
                  key={step}
                >
                  {steps[step].textField}
                </Grid>
              )}

              <Grid item sx={{ marginBottom: '20px', width: '100%' }}>
                <PrimaryButton
                  loadingLogic={step === 2 ? true : false}
                  sx={{ fontStyle: 'Semibold S' }}
                  disabled={Boolean(steps[step].disabled)}
                  onClick={() => {
                    if (Boolean(steps[step]['buttonClick']))
                      steps[step].buttonClick();
                  }}
                >
                  {steps[step].buttonText}
                </PrimaryButton>
              </Grid>
              {Boolean(steps[step].close) && (
                <Grid item>
                  <Button
                    variant="text"
                    sx={{
                      marginBottom: '0px',
                      color: '#383A49',
                      padding: '0',
                      fontStyle: 'Semibold S',
                    }}
                    onClick={() => {
                      setOpen(false);
                    }}
                  >
                    {tcommon('cancel')}
                  </Button>
                </Grid>
              )}
            </Grid>
          </Sheet.Content>
        </Sheet.Container>

        <Sheet.Backdrop />
      </Sheet>
    </>
  );
}
