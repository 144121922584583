import { Grid, Typography } from '@mui/material';
//import ZenOwnLogo from '../../assets/insurance/ZenOwn-logo.svg';
import ZenOwnLogo from '../../assets/insurance/ZenOwn.png';
import ZenOwnLogo2 from '../../assets/insurance/ZenOwn@2x.png';
import ZenOwnLogo3 from '../../assets/insurance/ZenOwn@3x.png';
import Allianz from '../../assets/insurance/Allianz.png';
import Allianz2 from '../../assets/insurance/Allianz@2x.png';
import Allianz3 from '../../assets/insurance/Allianz@3x.png';

export const LogoHeader: React.FC = () => {
  return (
    <Grid
      container
      direction="row"
      justifyContent="space-between"
      alignItems="center"
      sx={{
        width: '100%',
        minWidth: '100%',
        paddingTop: '18px',
        paddingInline: '9px',
      }}
    >
      <Grid item>
        <img
          src={ZenOwnLogo}
          srcSet={`${ZenOwnLogo} 1x, ${ZenOwnLogo2} 2x, ${ZenOwnLogo3} 3x`}
          style={{
            width: '123px',
            height: '25.76px',
          }}
        />
      </Grid>

      <Grid item>
        {
          <img
            src={Allianz}
            srcSet={`${Allianz} 1x, ${Allianz2} 2x, ${Allianz3} 3x`}
            style={{
              height: '28px',
              width: '112.85px',
            }}
          />
        }
      </Grid>
    </Grid>
  );
};

export default LogoHeader;
