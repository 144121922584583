import { useTranslation, Trans } from 'react-i18next';
import { namespaces } from '@zenown-insurance/i18n-service';
import {
  Typography,
  ListItem,
  ListItemText,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  List,
  ListItemIcon,
  Checkbox,
  ListItemSecondaryAction,
  Box,
  Grid,
} from '@mui/material';
import {
  getPriceFormatted,
  getInsurancePriceForQuote,
} from '@zenown-insurance/services/requests';

import BlueUnCheckBoxIcon from './../../../../assets/insurance/blueUnCheckBoxIcon.svg';
import BlueCheckBoxIcon from './../../../../assets/insurance/blueCheckBoxIcon.svg';
import PrimaryButton from '../../../../../lib/components/primary-button/PrimaryButton';
import { useContext, useEffect, useState } from 'react';
import { makeStyles } from '@mui/styles';
import InsureProductProcessContext from '../../../../../lib/contexts/InsureProductProcessContext';
import NoteIcon from './../../../../assets/insurance/note.svg';
import ProfileIcon from './../../../../assets/insurance/user-square.svg';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import moment from 'moment';
import ReturnButton from 'libs/ui/src/lib/components/return-button/ReturnButton';
import { InsuranceLayout } from 'libs/ui/src/lib/components/zenown-insurance';
const useStyles = makeStyles({
  MuiAccordionroot: {
    '*.MuiAccordionSummary-expandIconWrapper': {
      display: 'none',
    },
    '&.MuiAccordion-root:before': {
      backgroundColor: '#F7F7FC',
    },
  },
  Summary: {
    '&.MuiAccordion-root:before': {
      backgroundColor: '#F7F7FC',
    },
  },
});

export function LegalForm() {
  const classes = useStyles();
  const {
    tUserForm: t,
    insureProduct,
    quote,
    item,
    userInfo,
    surveyAnswers,
    trackEvent,
    checkingPhotos,
    acceptedPhotos,
    setCheckingPhotos,
  } = useContext(InsureProductProcessContext);
  useEffect(() => {
    if (checkingPhotos && acceptedPhotos[0] && acceptedPhotos[1]) {
      setCheckingPhotos(false);
      localStorage.setItem('checkingPhotos', 'false');
      setTimeout(() => {
        insureProduct();
      }, 0);
    }
  }, [acceptedPhotos]);
  const [downloadedFile, setDowloandedFiles] = useState<boolean>(false);

  const { t: legalText } = useTranslation(namespaces.pages.legal);

  const paragraphs = [
    {
      title: legalText('articles.1.title'),
      icon: NoteIcon,
      body: [
        {
          label: legalText('articles.1.product'),
          value: item?.brand ? item?.brand + ' ' : +item?.name,
        },
        {
          label: legalText('articles.1.price'),
          value: getPriceFormatted(item?.price || 0),
        },
        {
          label: legalText('articles.1.premiumPrice'),
          value: getInsurancePriceForQuote(quote),
        },
        {
          label: legalText('articles.1.start'),
          value: moment().format('DD.MM.YYYY'),
        },
        {
          label: legalText('articles.1.end'),
          value: '*' + moment().add(1, 'y').format('DD.MM.YYYY'),
        },
      ],
      renewal: legalText('articles.1.renewal'),
    },
    {
      title: legalText('articles.2.title'),
      icon: ProfileIcon,
      body: [
        {
          label: legalText('articles.2.name'),
          value: userInfo.firstName + ' ' + userInfo.lastName,
        },
        {
          label: legalText('articles.2.gender'),
          value: userInfo?.gender || 'Male',
        },

        {
          label: legalText('articles.2.email'),
          value: userInfo?.email,
        },
        {
          label: legalText('articles.2.address'),
          value: ' ' + userInfo?.address,
        },
        {
          label: legalText('articles.2.zip'),
          value: ' ' + userInfo?.postal_code,
        },
        { label: legalText('articles.2.city'), value: ' ' + userInfo?.city },
        {
          label: legalText('articles.2.country'),
          value: ' ' + userInfo?.country,
        },
      ],
    },
  ];
  const aggreements = [
    'checkboxes.1',
    'checkboxes.2',
    'checkboxes.3',
    'checkboxes.4',
    //'checkboxes.5',
  ];
  const [openParagraphs, setOpenParagraphs] = useState<string[]>([
    paragraphs[0].title,
  ]);
  const [agreedOn, setAgreedOn] = useState<number[]>([]);
  const [expandedData, setExpandedData] = useState<any>();
  useEffect(() => {
    trackEvent({
      event: 'Page Visit',
      action: 'legal information',
    });
  }, []);
  if (expandedData) {
    return (
      <>
        <div
          style={{
            minHeight: '100vh!important',
            minWidth: '100vw!important',
            position: 'fixed',
            top: 0,
            left: 0,
            height: '100vh',
            width: '100vw',

            background: 'white',
            zIndex: '999',
          }}
        >
          <InsuranceLayout>
            <Grid
              container
              direction="row"
              justifyContent="space-between"
              alignItems="center"
              sx={{ marginTop: '0px' }}
            ></Grid>
            <div style={{ width: '100%' }}>
              {' '}
              <ReturnButton
                style={{ top: '20px' }}
                onClick={() => setExpandedData(undefined)}
                close={false}
              />
              <Typography
                sx={{
                  color: '#383A49',
                  fontSize: '26px',
                  fontWeight: '700',
                  lineHeight: '32px',
                  marginTop: '76px',
                  marginBottom: '28px',
                }}
              >
                {expandedData.title}
              </Typography>
              <Box
                sx={{
                  height: 'auto',
                  backgroundColor: '#F6F6F7',
                  borderRadius: '10px',
                  paddingTop: '1px',
                  paddingBottom: '21px',
                }}
              >
                {' '}
                <List sx={{ padding: '0px', gap: '20px' }}>
                  {expandedData.body.map((detail: any, i: any) =>
                    detail.value ? (
                      <ListItem
                        key={i}
                        sx={{ height: '22px', marginTop: '20px' }}
                      >
                        <ListItemText
                          primary={
                            <Typography color="#9CA0AB" variant="subtitle1">
                              {detail.label}
                            </Typography>
                          }
                        />
                        <ListItemSecondaryAction
                          sx={{ width: '70%', textAlign: 'right' }}
                        >
                          {' '}
                          <Typography variant="subtitle1">
                            {detail?.value?.length > 50
                              ? detail.value.substring(0, 50) + ' ...'
                              : detail.value}
                          </Typography>
                        </ListItemSecondaryAction>
                      </ListItem>
                    ) : null
                  )}
                </List>
              </Box>{' '}
              {
                <Typography
                  color="#9CA0AB"
                  variant="subtitle1"
                  sx={{
                    maxHeight: '22px',
                    marginTop: '15px',
                    paddingLeft: '16px',
                  }}
                >
                  {expandedData.renewal}
                </Typography>
              }
            </div>
          </InsuranceLayout>
        </div>
      </>
    );
  }

  return (
    <div style={{ position: 'relative' }}>
      <div style={{ marginTop: '10px' }}>
        {' '}
        {paragraphs.map((p, i) => (
          <div key={i}>
            {' '}
            <Accordion
              key={i}
              expanded={false}
              sx={{ border: 'none', boxShadow: 'none', paddingInline: '0px' }}
              classes={{
                root: classes.MuiAccordionroot,
              }}
            >
              <AccordionSummary
                expandIcon={<></>}
                sx={{
                  padding: '20px',
                  paddingInline: '0px',
                  maxHeight: '44px',
                  borderBottom: '1px solid #DFE0E5',
                }}
                aria-controls="panel1a-content"
                id="panel1a-header"
                onClick={() => {
                  trackEvent({
                    event: 'Page Visit',
                    action:
                      i === 0
                        ? 'Review Insurance Details'
                        : 'Review personal Data',
                  });
                  /*trackEvent({
                    event: 'Button click',
                    action: p?.title + '',
                  });*/
                  setExpandedData(p);
                  /* if (openParagraphs.includes(p.title)) {
                    setOpenParagraphs((prev) =>
                      prev.filter((x) => x !== p.title)
                    );
                  } else {
                    setOpenParagraphs((prev) => [...prev, p.title]);
                  }*/
                }}
              >
                <Typography
                  variant="h2"
                  sx={{
                    color: '#0179FF',
                    fontSize: '14px',
                    fontWeight: '600',
                    lineHeight: '21px',
                  }}
                >
                  <img
                    src={p.icon}
                    style={{
                      height: '24x',
                      width: '24px',
                      marginRight: '16px',
                      verticalAlign: 'middle',
                    }}
                  />
                  {p.title}
                </Typography>
              </AccordionSummary>
              <AccordionDetails
                sx={{
                  paddingInline: '0px',
                  padding: '0px',
                  paddingBottom: '20px',
                }}
              >
                <Typography
                  variant="subtitle1"
                  sx={{
                    fontSize: '14px',
                    fontWeight: '400',

                    lineHeight: '21px',

                    letterSpacing: '-0.3px',

                    color: ' #9CA0AB',
                  }}
                ></Typography>
              </AccordionDetails>
            </Accordion>
          </div>
        ))}
      </div>

      <List sx={{ marginTop: '5px', paddingTop: '0px' }}>
        {aggreements.map((a: string, index: number) => {
          return (
            <ListItem
              key={index}
              disablePadding
              alignItems="flex-start"
              onClick={async () => {
                if (index === 0 && !downloadedFile) {
                  async function downloadFile(url: string) {
                    const alink = document.createElement('a');
                    alink.href = url;
                    let name = url.split('/')[url.split('/').length - 1];
                    if (!name.includes('.pdf')) name = name + '.pdf';
                    alink.download = name;
                    //alink.target = '_blank';
                    document.body.appendChild(alink);
                    alink.click();
                    document.body.removeChild(alink);
                  }
                  const aDownload = legalText('downloadDocs');
                  //const aDownload = legalText('a');
                  //const bDownload = legalText('b');
                  await downloadFile(aDownload);
                  //await downloadFile(bDownload);
                  setDowloandedFiles(true);
                }
                if (agreedOn.includes(index)) {
                  trackEvent({
                    event: 'Button click',
                    action: 'Consent uncheck ' + (index + 1),
                  });
                  setAgreedOn((prev) => prev.filter((x) => x !== index));
                } else {
                  trackEvent({
                    event: 'Button click',
                    action: 'Consent check ' + (index + 1),
                  });
                  if (false && index === aggreements.length - 1) {
                    setAgreedOn(aggreements.map((x, i) => i));
                    return;
                  } else {
                    setAgreedOn((prev) => [...prev, index]);
                    /*setTimeout(() => {
                      if (
                        aggreements
                          .slice(0, aggreements.length - 1)
                          .every((ax, j) => agreedOn.some((a) => j === a))
                      ) {
                        setAgreedOn((prev) => [
                          ...prev,
                          aggreements.length - 1,
                        ]);
                      }
                    }, 0);*/
                  }
                }
              }}
              sx={{
                maxWidth: '100%',
                marginTop: '20px',
                gap: '0px',
                paddingTop: '0px',
              }}
            >
              <ListItemIcon
                sx={{
                  maxWidth: '32px',
                  minWidth: '32px !important',
                  maxHeight: '24px',
                  padding: 0,
                }}
              >
                <Checkbox
                  checkedIcon={
                    <img
                      style={{ width: '24px', height: '24px' }}
                      src={BlueCheckBoxIcon}
                      alt="Checked icon"
                    />
                  }
                  icon={
                    <img
                      style={{ width: '24px', height: '24px' }}
                      src={BlueUnCheckBoxIcon}
                      alt="Unchecked icon"
                    />
                  }
                  sx={{
                    verticalAlign: 'center',
                    width: '24px',
                    height: '24px',
                  }}
                  checked={agreedOn.includes(index)}
                />
              </ListItemIcon>
              <ListItemText sx={{ minWidth: 'calc(100% - 32px);' }}>
                {' '}
                <Typography
                  variant="caption"
                  sx={{
                    marginTop: '25px',
                    fontSize: '12px',

                    lineHeight: '18px',

                    letterSpacing: '-0.3px',
                    ...(index === aggreements.length - 1 && false
                      ? {
                          textDecorationLine: 'underline',
                          color: '#383A49',
                          fontWeight: '600',
                        }
                      : { fontWeight: '500', color: '#383A49' }),
                  }}
                >
                  <Trans
                    i18nKey={a}
                    t={legalText}
                    components={{
                      underline: (
                        <span
                          style={{
                            textDecoration: 'underline',
                            color: '#0179FF',
                            //fontSize: '12px',
                          }}
                        >
                          {legalText(a)}
                        </span>
                      ),
                    }}
                  ></Trans>
                </Typography>
              </ListItemText>
            </ListItem>
          );
        })}{' '}
      </List>
      {false && (
        <Typography
          sx={{
            marginTop: '25px',
            fontSize: '12px',
            fontWeight: '400',

            lineHeight: '18px',

            letterSpacing: '-0.3px',

            color: '#9CA0AB',
          }}
        >
          {legalText('extraLegalArtile')}
        </Typography>
      )}

      <PrimaryButton
        loadingLogic={true}
        disabled={
          [0, 1, 2, 3].every((x) => agreedOn.some((y) => y === x))
            ? false
            : true
        }
        sx={{
          marginTop: '25px',
          marginBottom: '30px',
        }}
        onClick={() => {
          trackEvent({
            event: 'Button click',
            action: 'Confirm',
          });
          insureProduct();
        }}
      >
        <Typography variant="button" color="#FFFFFF">
          {t('confirm')}
        </Typography>
      </PrimaryButton>
    </div>
  );
}

export default LegalForm;
