import {
  Grid,
  Typography,
  Card,
  Link,
  Divider,
  Checkbox,
  ListItem,
  ListItemText,
  SvgIcon,
  CardActionArea,
  List,
} from '@mui/material';
import ReturnButton from './../../../../components/return-button/ReturnButton';
import ZenOwnLogo from './../../../../assets/insurance/ZenOwn.png';
import ZenOwnLogo2 from './../../../../assets/insurance/ZenOwn@2x.png';
import ZenOwnLogo3 from './../../../../assets/insurance/ZenOwn@3x.png';
import { ReactComponent as CheckedRoundedCheckbox } from './../../../../assets/insurance/checked-rounded-checkbox.svg';
import DisabledCheckedIcon from './../../../../assets/insurance/disabled-checked-icon.svg';
import CheckedIcon from './../../../../assets/insurance/checked-icon.svg';
import TheftIcon from './../../../../assets/insurance/theft-sheild-icon.svg';

import PrimaryButton from '../../../../../lib/components/primary-button/PrimaryButton';
import { useState, useContext, useEffect } from 'react';
import InsureProductProcessContext from '../../../../../lib/contexts/InsureProductProcessContext';
import {
  getPriceFormatted,
  getInsurancePriceForQuote,
} from '@zenown-insurance/services/requests';
import { LongFooter } from 'libs/ui/src/lib/components/zenown-insurance/LongFooter';

export function QuoteProposition() {
  const [showPriceInHeader, setShowPriceInHeader] = useState<boolean>(false);
  const [width, setWidth] = useState(375);
  useEffect(() => {
    const element = document.getElementById('layout-container');
    if (element) {
      const positionInfo = element?.getBoundingClientRect();
      setWidth(positionInfo.width);
    }
  }, []);
  const {
    tcommon,
    tQuoteProposition: t,
    tUserForm,
    item,
    quote,
    passToPremium,
    setOpenConfirmationPopUP,
    submitQuote,
    trackEvent,
  } = useContext(InsureProductProcessContext);

  const [offset, setOffset] = useState<number>(0);
  const setScroll = () => {
    setOffset(window.pageYOffset);
  };

  useEffect(() => {
    trackEvent({
      event: 'Page Visit',
      action: 'Your Quote',
    });
    trackEvent({
      event: 'Landing Page',
      action: 'Your Quote',
    });
    window.addEventListener('scroll', setScroll);
    return () => {
      window.removeEventListener('scroll', setScroll);
    };
  }, []);
  useEffect(() => {
    if (offset > 0) {
      console.log(offset);
      setShowPriceInHeader(true);
    } else {
      setShowPriceInHeader(false);
    }
  }, [offset]);
  const insuranceFeatures = [
    {
      description: t('benefitsRisksList.1'),
    },
    {
      description: t('benefitsRisksList.2'),
    },
    {
      description: t('benefitsRisksList.3'),
    },
    {
      description: t('benefitsRisksList.4'),
    },
  ];
  const calculateQuotePrice = () => {
    if (!quote) {
      return '';
    }

    return getInsurancePriceForQuote(quote);
  };

  const getProductNameAndPrice = () => {
    if (item == null) {
      return '';
    }
    let productName = '';

    if (item.brand) {
      productName += item.brand;
    }

    if (item.name) {
      if (productName.length > 0) {
        productName += ' ';
      }

      productName += item.name;
    }

    if (productName.length > 0) {
      productName += ': ';
    }

    productName += getPriceFormatted(item.price ?? 0);

    return productName;
  };

  const IconWithoutPremium = () => {
    return (
      <SvgIcon
        component={() => (
          <div
            style={{
              width: '22px',
              height: '22px',
              maxWidth: '22px',
              maxHeight: '22px',
              background: '#FFFFFF',
              borderRadius: '50%',
              border: '1px solid #DFE0E5',
              margin: '0px',
              marginRight: '15px',
            }}
          ></div>
        )}
        inheritViewBox
        style={{
          marginRight: '15px',
          width: '24px',
          height: '24px',
          maxWidth: '24px',
          maxHeight: '24px',
          color: '#FFFFFF',
          border: 'none',
        }}
      />
    );
  };

  const IconWithPremium = () => {
    return (
      <SvgIcon
        component={CheckedRoundedCheckbox}
        inheritViewBox
        style={{
          marginRight: '15px',
          width: '24px',
          height: '24px',
          border: 'none',
        }}
      />
    );
  };

  return (
    <div
      style={{
        position: 'relative',
        overflowY: 'scroll',
        paddingBottom: '15px',
      }}
    >
      <ListItem
        sx={{
          left: '50%',
          transform: 'translateX(-50%)',
          background: 'white',
          zIndex: '55',
          height: '65px',
          padding: '15px',
          paddingInline: '15px',

          paddingBottom: '11px',
          boxShadow: '0 4px 2px -2px rgba(193, 193, 193, 0.16)',
          position: 'fixed',
          width: width + 'px',
          maxWidth: width + 'px',
          //top: Math.min(offset - 65, 0) + 'px !important',
          opacity: offset > 80 ? 1 : offset < 50 ? 0 : (offset - 50) / 30,
        }}
        secondaryAction={
          <img
            src={ZenOwnLogo}
            srcSet={`${ZenOwnLogo} 1x, ${ZenOwnLogo2} 2x, ${ZenOwnLogo3} 3x`}
            style={{
              float: 'right',
              width: '123px',
              height: '27px',
            }}
            alt="ZenProtect Logo"
          />
        }
      >
        <ListItemText
          sx={{
            margin: '0px',
            opacity: offset > 80 ? 1 : offset < 65 ? 0 : (offset - 65) / 15,
          }}
          primary={
            <Typography variant="h3">{calculateQuotePrice()}</Typography>
          }
          secondary={
            <Typography
              variant="caption"
              color="#9CA0AB"
              sx={{ fontWeight: '500', LetterSpacing: '-0.3px' }}
            >
              {tUserForm('coversFor')}
              <Typography variant="caption"> 1 {tUserForm('year')}</Typography>
            </Typography>
          }
        />
      </ListItem>

      <ListItem
        sx={{
          //left: '50%',
          //transform: 'translateX(-50%)',
          //background: 'transparent',
          zIndex: '55',
          height: '65px',
          padding: '0px',
          //paddingInline: '15px',
          //paddingBottom: '11px',
          boxShadow: 'none',
          // position: 'fixed',

          //maxWidth: '430px',
          //top: -offset + 'px !important',
          //opacity: offset === 0 ? 1 : offset / 65,
        }}
      >
        <ListItemText
          sx={{ margin: '0px', zIndex: '55' }}
          primary={
            <ReturnButton
              style={{ top: '-5px', cursor: 'pointer' }}
              close={true}
              onClick={() => {
                trackEvent({
                  event: 'Button click',
                  action: 'Exit',
                });
                setOpenConfirmationPopUP(true);
              }}
            />
          }
        />
        <img
          src={ZenOwnLogo}
          srcSet={`${ZenOwnLogo} 1x, ${ZenOwnLogo2} 2x, ${ZenOwnLogo3} 3x`}
          style={{
            float: 'right',
            width: '123px',
            height: '27px',
          }}
          alt="ZenProtect"
        />
      </ListItem>

      <div
        style={{
          marginTop: showPriceInHeader ? '0px' : '0px',
          position: 'relative',

          width: '100%',
          height: 'auto',
          //maxHeight: '800px',
        }}
      >
        <div>
          <Grid
            container
            direction="column"
            justifyContent="space-between"
            alignItems="stretch"
          >
            <Grid item>
              {' '}
              <Typography variant="h2">{t('yourQuote')}</Typography>
              <Typography
                variant="h1"
                sx={{ marginTop: '25px', fontWeight: '600' }}
              >
                {calculateQuotePrice()}
              </Typography>{' '}
              <Typography variant="subtitle1" sx={{ marginTop: '15px' }}>
                {t('coversFor')} {'1 ' + t('year')}
              </Typography>
            </Grid>
            <Divider
              sx={{
                width: '100%',
                //maxWidth: '345px',
                margin: 'auto',
                marginTop: '20px',
              }}
            />
            <Grid item>
              <Typography
                variant="h2"
                sx={{ marginBottom: '20px', marginTop: '20px' }}
              >
                {t('yourProduct')}
              </Typography>

              <Typography variant="body1" sx={{ marginTop: '20px' }}>
                {getProductNameAndPrice()}
              </Typography>

              {/* <Typography variant="caption" sx={{ fontWeight: '600' }}>
                <Link
                  underline="always"
                  onClick={() => setOpenConfirmationPopUP(true)}
                  sx={{ cursor: 'pointer' }}
                >
                  {t('switchProduct')}
                </Link>
              </Typography> */}
            </Grid>
            <Divider
              sx={{
                width: '100%',
                maxWidth: '345px',
                margin: 'auto',
                marginTop: '20px',
              }}
            />
            <Grid item>
              {' '}
              <Typography variant="h2" sx={{ marginTop: '20px' }}>
                {t('beneiftsRisks')}
              </Typography>
              <List sx={{ marginTop: '20px', padding: 0 }}>
                {insuranceFeatures.map((feat, index) => (
                  <ListItem
                    alignItems="center"
                    key={index}
                    sx={{ padding: '0px', marginTop: '15px' }}
                  >
                    <ListItemText
                      sx={{ margin: '0' }}
                      primary={
                        <>
                          <img
                            src={CheckedIcon}
                            style={{
                              height: '20px',
                              width: '20px',
                              marginRight: '10px',
                              verticalAlign: 'middle',
                            }}
                            alt="Checkmark icon"
                          />
                          <Typography
                            variant="caption"
                            color={undefined}
                            sx={{ fontWeight: '500', letterSpacing: '-0.3px' }}
                          >
                            {feat.description}
                          </Typography>
                        </>
                      }
                    />
                  </ListItem>
                ))}
              </List>
            </Grid>
          </Grid>{' '}
          <PrimaryButton
            loadingLogic={true}
            sx={{ marginTop: '30px' }}
            onClick={() => {
              trackEvent({ event: 'Button click', action: 'Continue' });
              submitQuote();
            }}
          >
            {tcommon('continue')}
          </PrimaryButton>
          {false && <LongFooter />}
        </div>
      </div>
    </div>
  );
}

export default QuoteProposition;
