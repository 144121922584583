import { Trans, useTranslation } from 'react-i18next';
import { namespaces } from '@zenown-insurance/i18n-service';
import { Grid, Typography, Link, IconButton } from '@mui/material';
import ReturnButton from './../../../../components/return-button/ReturnButton';
import RVLogo from './../../../../assets/insurance/r+v-logo.png';

import { useContext, useState, useEffect } from 'react';
import Bulb from '../../../../assets/insurance/Bulb.png';
import Bulb2 from '../../../../assets/insurance/Bulb@2x.png';
import Bulb3 from '../../../../assets/insurance/Bulb@3x.png';
import WatchCardPicture from '../../../../assets/insurance/WatchCardPicture.png';
import WatchCardPicture2 from '../../../../assets/insurance/WatchCardPicture@2x.png';
import WatchCardPicture3 from '../../../../assets/insurance/WatchCardPicture@3x.png';
import WatchBackPicture from '../../../../assets/insurance/WatchBackPicture.png';
import WatchBackPicture2 from '../../../../assets/insurance/WatchBackPicture@2x.png';
import WatchBackPicture3 from '../../../../assets/insurance/WatchBackPicture@3x.png';
import CameraPlus from '../../../../assets/insurance/CameraPlus.png';
import CameraPlus2 from '../../../../assets/insurance/CameraPlus@2x.png';
import CameraPlus3 from '../../../../assets/insurance/CameraPlus@3x.png';
import HowBackPicture from '../../../../assets/insurance/HowBackPicture.png';
import HowBackPicture2 from '../../../../assets/insurance/HowBackPicture@2x.png';
import HowBackPicture3 from '../../../../assets/insurance/HowBackPicture@3x.png';
import InsureProductProcessContext from '../../../../../lib/contexts/InsureProductProcessContext';
import ApplePayForm from '../../../../../lib/components/zenown-insurance/ApplePayForm';
import StripePayForm from '../../../../../lib/components/zenown-insurance/StripePayForm';
import ErrorMessage from '../../../../../lib/components/error-message/ErrorMessage';
import SerialNumberHow from './SerialNumberHow';
import LegalForm from './LegalForm';
import { LongFooter } from 'libs/ui/src/lib/components/zenown-insurance/LongFooter';
import PrimaryButton from 'libs/ui/src/lib/components/primary-button/PrimaryButton';
import { UploadSection } from 'libs/ui/src/lib/components/zenown-insurance/UploadSection';
import Tabs from '@mui/material/Tabs';
import Sheet from 'react-modal-sheet';
import Draggable from 'react-draggable';
import CancelIcon from '@mui/icons-material/Cancel';

import Tab from '@mui/material/Tab';
import { HowToPictureComponent } from './PictureUpload';
import { AllianzInsuranceService } from '@zenown-insurance/services/requests';
const embeddedInsuranceService = new AllianzInsuranceService();

export function PictureSerialUpload() {
  const [isApplePay] = useState<boolean>(true);
  const [opacity, setOpacity] = useState(1);

  const handleDrag = (e: any, ui: any) => {
    const { y } = ui;
    const windowHeight = window.innerHeight;
    // This logic assumes that the opacity will decrease linearly
    // with the vertical distance dragged, and the message box will
    // completely fade away when it is dragged to the bottom of the screen.
    const newOpacity = 1 - Math.min(Math.abs(y) / 68, 1);
    setOpacity(newOpacity);
  };

  const {
    tSerial: t,
    quote,
    currentProcessStep,
    setCurrentProcessStep,
    errorMessage,
    setErrorMessage,
    apiErrorMessage,
    setApiErrorMessage,
    serialAccepted,
    setPayementCancelDialog,

    setIsLoading,
    watchSerialPicture,
    setSerialWatchPicture,
    watchCardPicture,
    setSerialCardPicture,
    trackEvent,
    setProgress,
    receipt,
    constraintImage,
  } = useContext(InsureProductProcessContext);
  useEffect(() => {
    trackEvent({
      event: 'Page Visit',
      action: 'Take a photo of your watch',
    });
  }, []);
  const { t: serialT } = useTranslation(namespaces.pages.serialNumberPage);
  const [step, setStep] = useState(watchCardPicture?.length ? 1 : 0);
  const [showHowSheet, setShowHowsheet] = useState(false);
  const [err, setErr] = useState<any>();

  const tabs = [
    {
      title: t('pictureUpload.serial.back.title'),
      files: watchSerialPicture,
      setFiles: (e: any) => {
        setSerialCardPicture(undefined);
        setSerialWatchPicture(e);
      },
      sheet: {
        title: t('pictureUpload.serial.back.how.title'),
        description: t('pictureUpload.serial.back.how.description'),
        image: (
          <img
            src={HowBackPicture}
            srcSet={`${HowBackPicture} 1x, ${HowBackPicture2} 2x, ${HowBackPicture3} 3x`}
            style={{
              width: '100%',
              marginBottom: '15px',
            }}
          />
        ),
      },
      component: (
        <>
          <img
            src={WatchBackPicture}
            srcSet={`${WatchBackPicture} 1x, ${WatchBackPicture2} 2x, ${WatchBackPicture3} 3x`}
            style={{
              width: '210.84px',
              height: '201.1px',
              position: 'absolute',
              marginInline: 'auto',
              left: 0,
              right: 0,
            }}
          />
        </>
      ),
    },
    {
      title: t('pictureUpload.serial.card.title'),
      files: watchCardPicture,
      setFiles: (e: any) => {
        setSerialCardPicture(e);
        setSerialWatchPicture(undefined);
      },
      sheet: {
        title: t('pictureUpload.serial.card.how.title'),
        description: t('pictureUpload.serial.card.how.description'),
        image: (
          <img
            src={HowBackPicture}
            srcSet={`${HowBackPicture} 1x, ${HowBackPicture2} 2x, ${HowBackPicture3} 3x`}
            style={{
              width: '100%',
              marginBottom: '15px',
            }}
          />
        ),
      },
      component: (
        <>
          <img
            src={WatchCardPicture}
            srcSet={`${WatchCardPicture} 1x, ${WatchCardPicture2} 2x, ${WatchCardPicture3} 3x`}
            style={{
              width: '232.99px',
              height: '147.1px',

              position: 'absolute',
              marginInline: 'auto',
              left: 0,
              right: 0,
            }}
          />
        </>
      ),
    },
  ];
  const onUpload = async () => {
    trackEvent({
      event: 'Button Click',
      action: 'Submit',
    });
    try {
      setIsLoading(true);
      if (tabs[0].files?.length) {
        await onUploadFile(tabs[0].files, 1);
      }
      if (tabs[1].files?.length) {
        await onUploadFile(tabs[1].files, 2);
      }
      setErr(undefined);
    } catch (err: any) {
      if (err.type === 1) setErr('pictureUpload.watch.uploadError.1');
      if (err.type === 2) setErr('pictureUpload.watch.uploadError.2');
    } finally {
      setIsLoading(false);
      setProgress(undefined);
    }
  };
  const onUploadFile = async (file: any, type: number) => {
    let e = file;
    try {
      const { data: receiptData } =
        await embeddedInsuranceService.getPictureUploadUrl();
      let { uid, uploadURL } = receiptData;
      if (!uid || !uploadURL) throw 'failed fetching upload url ';
      const newFile = await constraintImage(e, 100, 1);
      console.log('**** e', file);
      let binaryFile: any;
      await fetch(newFile[0].data_url).then(
        async (res) => (binaryFile = await res.blob())
      );
      const options = {
        onUploadProgress: (progressEvent: any) => {
          const { loaded, total } = progressEvent;
          let percent = Math.floor((loaded * 100) / total);

          setProgress(percent);
        },
      };
      await embeddedInsuranceService.uploadFile(
        uploadURL + '',
        binaryFile,
        options
      );
      const notification: any =
        await embeddedInsuranceService.notifyWithThePhotoUpload(
          receiptData?.uid,
          type,
          receipt.uid
        );
      if (
        !quote &&
        localStorage.getItem('currentProcessStep') !== 'quoteProposition'
      ) {
        setCurrentProcessStep('processing');
        localStorage.setItem('currentProcessStep', 'processing');
      } else {
        setCurrentProcessStep('quoteProposition');
        localStorage.setItem('currentProcessStep', 'quoteProposition');
      }
    } catch (err) {
      throw { err, type };
    } finally {
    }
  };

  return (
    <div style={{ position: 'relative' }}>
      {Boolean(errorMessage) && (
        <Draggable
          onDrag={handleDrag}
          axis="y"
          onStop={() => {
            if (opacity < 0.2) setErrorMessage(undefined);
          }}
        >
          <div
            onMouseUp={() => {
              setErrorMessage(undefined);
            }}
            style={{
              position: 'absolute',
              backgroundColor: '#FF2D2D',
              boxShadow: '0px 2px 8px rgba(0, 0, 0, 0.1)',
              borderRadius: '10px',
              padding: '15px',
              display: 'flex',
              flexDirection: 'row',
              gap: '14px',
              zIndex: '9999',
              opacity: opacity,
            }}
          >
            <IconButton
              onClick={() => {
                setErrorMessage(undefined);
              }}
            >
              <CancelIcon
                sx={{ color: 'white', height: '21px', width: '21px' }}
              />
            </IconButton>

            <Typography
              sx={{
                color: 'white',
                fontWeight: '500',
                fontSize: '14px',
                lineHeight: '19px',
              }}
            >
              {errorMessage}
            </Typography>
          </div>
        </Draggable>
      )}
      <Grid
        container
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        sx={{ marginTop: '20px' }}
      >
        <Grid item>
          <ReturnButton
            style={{ position: 'relative' }}
            onClick={() => {
              setCurrentProcessStep('watchpictureupload');
            }}
          />
        </Grid>
      </Grid>

      <div
        style={{
          marginTop: '33px',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          textAlign: 'center',

          alignItems: 'stretch',
        }}
      >
        <Typography variant="h1">{t('pictureUpload.serial.title')}</Typography>
        <Typography
          variant="subtitle1"
          sx={{ marginTop: '16px', textAlign: 'left' }}
        >
          <Trans
            i18nKey="pictureUpload.serial.description"
            t={t}
            components={{
              strong: <strong>{t('pictureUpload.serial.description')}</strong>,
              underline: (
                <span style={{}}>{t('pictureUpload.serial.description')}</span>
              ),
            }}
          ></Trans>
        </Typography>
      </div>
      <div
        style={{
          marginTop: '33px',
          height: '226.14px',
          position: 'relative',
        }}
      >
        <Tabs
          variant="fullWidth"
          value={step}
          style={{ width: '100%', marginBottom: '36px' }}
          onChange={(event: React.SyntheticEvent, newValue: number) => {
            setStep(newValue);
          }}
        >
          <Tab
            label={tabs[0].title}
            sx={{
              textAlign: 'center',
              fontWeight: '600',
              fontSize: '12px',
              lineHeight: '21px',
              textTransform: 'upperCase',
            }}
          />
          <Tab
            label={tabs[1].title}
            sx={{
              textAlign: 'center',
              fontWeight: '600',
              fontSize: '12px',
              lineHeight: '21px',
              textTransform: 'upperCase',
            }}
          />
        </Tabs>
        <div
          style={{
            height: '201px',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'revert',
          }}
        >
          {tabs[step]?.component}
        </div>
      </div>
      <div
        style={{
          marginTop: '64px',
          display: 'flex',
          flexDirection: 'row',
          gap: '8px',
          alignItems: 'center',
        }}
      >
        <img
          src={Bulb}
          srcSet={`${Bulb} 1x, ${Bulb2} 2x, ${Bulb3} 3x`}
          style={{
            width: '36px',
            height: '36px',
          }}
        />

        <Typography
          variant="subtitle1"
          sx={{
            fontWeight: '500',
            fontSize: '12px',
            lineHeight: '22px',

            color: '#0179FF',
          }}
        >
          <Link
            underline="none"
            onClick={() => {
              let v = !showHowSheet;
              if (v) {
                trackEvent({
                  event: 'Landing Page',
                  action: "Here's How",
                });
              }
              setShowHowsheet(v);
            }}
          >
            <Trans
              i18nKey="pictureUpload.serial.hint"
              t={t}
              components={{
                underline: (
                  <span style={{ textDecoration: 'underline' }}>
                    {t('pictureUpload.serial.hint')}
                  </span>
                ),
              }}
            ></Trans>
          </Link>
        </Typography>
      </div>
      <div
        style={{
          marginTop: '23px',
          position: 'relative',
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'stretch',
          justifyContent: 'center',
        }}
        key={tabs[step]?.files?.length + step}
      >
        <UploadSection
          key={tabs[step]?.files?.length + step}
          text={t('pictureUpload.serial.uploadPhrase')}
          logo={
            <img
              src={CameraPlus}
              srcSet={`${CameraPlus} 1x, ${CameraPlus2} 2x, ${CameraPlus3} 3x`}
              style={{ width: '29px', height: '28px' }}
            />
          }
          onChange={async (e: any) => {
            trackEvent({
              event: 'Button Click',
              action: 'Upload a photo of serial number',
            });
            setErrorMessage(undefined);

            tabs[step].setFiles(e);
          }}
          files={tabs[step].files}
        />
      </div>
      <div>
        {err ? (
          <div
            style={{
              marginTop: '8px',
            }}
          >
            <ErrorMessage message={err} />
          </div>
        ) : null}
        <PrimaryButton
          loadingLogic={true}
          disabled={Boolean(
            !watchCardPicture?.length && !watchSerialPicture?.length
          )}
          onClick={async () => {
            onUpload();
          }}
          sx={{
            fontStyle: 'Semibold S',
            marginTop: '20px',
            marginBottom: '20px',
          }}
        >
          {t('pictureUpload.serial.submitPicture')}
        </PrimaryButton>
      </div>
      <Sheet
        style={{ maxWidth: '500px', margin: '0 auto', zIndex: 999 }}
        isOpen={showHowSheet}
        disableDrag={false}
        onClose={() => {
          setShowHowsheet(false);
        }}
      >
        <Sheet.Container style={{}}>
          <Sheet.Header />
          <Sheet.Content style={{ padding: '16px' }} disableDrag={true}>
            <HowToPictureComponent
              {...{
                title: tabs[step].sheet?.title,
                description: tabs[step].sheet?.description,
                image: tabs[step].sheet?.image,
              }}
            />
          </Sheet.Content>
        </Sheet.Container>

        <Sheet.Backdrop />
      </Sheet>
    </div>
  );
}

export default PictureSerialUpload;
