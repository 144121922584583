// eslint-disable-next-line @typescript-eslint/no-unused-vars
import styles from './app.module.scss';

import {
  Switch,
  Route,
} from 'react-router-dom';
import { ZenownInsuranceContainer } from '@zenown-insurance/zenown-insurance';

export function App() {
  return (
    <Switch>
      {' '}
      <Route path="/serial-number">
        <ZenownInsuranceContainer path="serial-number" />
      </Route>
      <Route path="/">
        <ZenownInsuranceContainer />
      </Route>
    </Switch>
  );
}

export default App;
