import { useTranslation } from 'react-i18next';
import { namespaces } from '@zenown-insurance/i18n-service';
import { Grid, Typography, MobileStepper } from '@mui/material';
import ReturnButton from './../../../../components/return-button/ReturnButton';
import RVLogo from './../../../../assets/insurance/r+v-logo.png';

import { useContext, useState, useEffect } from 'react';

import InsureProductProcessContext from '../../../../../lib/contexts/InsureProductProcessContext';
import ApplePayForm from '../../../../../lib/components/zenown-insurance/ApplePayForm';
import StripePayForm from '../../../../../lib/components/zenown-insurance/StripePayForm';
import ErrorMessage from '../../../../../lib/components/error-message/ErrorMessage';
import SerialNumberHow from './SerialNumberHow';
import LegalForm from './LegalForm';
import { LongFooter } from 'libs/ui/src/lib/components/zenown-insurance/LongFooter';

export function UserPayementForm() {
  const [isApplePay] = useState<boolean>(true);

  const {
    tUserForm: t,
    currentProcessStep,
    setCurrentProcessStep,
    errorMessage,
    apiErrorMessage,
    setApiErrorMessage,
    serialAccepted,
    setPayementCancelDialog,
    trackEvent,
  } = useContext(InsureProductProcessContext);
  const [registrationStep, setRegistrationStep] = useState(0);

  const { t: serialT } = useTranslation(namespaces.pages.serialNumberPage);

  useEffect(() => {
    switch (currentProcessStep) {
      case 'payementForm': {
        setRegistrationStep(0);
        break;
      }

      case 'legalForm': {
        setRegistrationStep(1);
        break;
      }
      case 'checkout': {
        setRegistrationStep(2);
        break;
      }
    }
  }, [currentProcessStep]);

  const registrationSteps = [
    {
      title: t('ApplePaytitle'),
      component: <ApplePayForm />,
      backState: 'quoteProposition',
    },

    {
      title: t('legalInformation'),
      component: <LegalForm />,
      backState: 'payementForm',
    },
    {
      title: t('creditCardtitle'),
      component: <StripePayForm />,
      backState: 'legalForm',
    },
  ];

  return (
    <div style={{ position: 'relative' }}>
      <Grid
        container
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        sx={{ marginTop: '20px' }}
      >
        <Grid item>
          <ReturnButton
            style={{ position: 'relative' }}
            onClick={() => {
              trackEvent({
                event: 'Button click',
                action: 'Back',
              });
              if (currentProcessStep === 'checkout') {
                setPayementCancelDialog(true);
              } else {
                setCurrentProcessStep(
                  registrationSteps[registrationStep].backState
                );
              }
            }}
          />
        </Grid>
        <Grid item sx={{ margin: '0px' }}>
          <MobileStepper
            sx={{
              background: 'transparent',
              width: '120px',
              marginTop: '0',
              padding: '0',
              '& .MuiMobileStepper-dot': {
                height: '3px',
                minWidth: '24px',
                borderRadius: '2px',
                background: '#DFE0E5',
              },
              '& .MuiMobileStepper-dotActive': {
                height: '3px',
                minWidth: '24px',
                borderRadius: '2px',
                background: '#383A49',
              },
              '& .MuiMobileStepper-dots': {
                width: '100%',
                display: 'grid',
                justifyContent: 'space-between',
                gridTemplateColumns: 'repeat(5, 20%)',
                margin: 'auto',
                gap: '8px',
              },
            }}
            steps={3}
            variant="dots"
            position="static"
            activeStep={registrationStep}
            nextButton={null}
            backButton={null}
          />
        </Grid>
        {!isApplePay ? (
          <Grid item>
            <img
              src={RVLogo}
              style={{
                height: '27px',
                width: '56px',
              }}
              alt="RVLogo"
            />
          </Grid>
        ) : null}
      </Grid>

      <div
        style={{
          marginTop: '33px',
        }}
      >
        <Typography variant="h1">
          {registrationSteps[registrationStep].title}
        </Typography>
        <div
          style={{
            position: 'relative',
            marginTop: '5px',
            marginBottom:
              (registrationStep === 0 || registrationStep === 3) &&
              currentProcessStep !== 'checkout'
                ? '45px'
                : '10px',
          }}
        >
          {(errorMessage || apiErrorMessage) &&
          currentProcessStep == 'payementForm' ? (
            <div
              style={{
                position: 'absolute',
                zIndex: -1,
                marginTop: '5px',
              }}
            >
              <ErrorMessage message={apiErrorMessage || errorMessage} />
            </div>
          ) : null}
        </div>

        {registrationSteps[registrationStep].component}
      </div>
      {false && <LongFooter />}
    </div>
  );
}

export default UserPayementForm;
