import { useTranslation, Trans } from 'react-i18next';
import { Grid, Link, Typography } from '@mui/material';
import { namespaces } from '@zenown-insurance/i18n-service';
import ReturnButton from '../return-button/ReturnButton';
import RVLogo from '../../assets/insurance/RV.png';
import RVLogo2 from '../../assets/insurance/RV@2x.png';
import RVLogo3 from '../../assets/insurance/RV@3x.png';
import { useEffect } from 'react';

export interface TermsConditionsProps {
  onClose: () => void;
}

export function TermsConditions(props: any) {
  const { onClose, trackEvent } = props;

  const { t: tupload } = useTranslation(namespaces.pages.uplaodPage);
  const t = (key: string) => tupload('termsOfServices.articles.' + key);

  useEffect(() => {
    window.scrollTo(0, 0);
    trackEvent({ event: 'Page Visit', action: 'Terms of Service' });
  }, []);
  const renderSections = () => {
    const sectionTexts = PolicyParagraps.map(
      (paragraph: { title: string; description: string }, index: number) => {
        return (
          <Grid item key={index + ''}>
            <Typography sx={{ marginBottom: '20px' }} variant="h3">
              {t('' + paragraph.title)}
            </Typography>
            <Typography variant="subtitle1">
              <Trans
                i18nKey={'' + paragraph.description}
                t={t}
                components={{
                  br: <br />,
                  email: (
                    <Link
                      style={{ color: '#383A49' }}
                      color="#383A49"
                      href={'mailto:' + t('paragraph.description')}
                    >
                      {t('paragraph.description')}
                    </Link>
                  ),
                  phone: (
                    <Link
                      style={{ color: '#383A49' }}
                      color="#383A49"
                      href={
                        'tel:' + t('paragraph.description').replace(' ', '')
                      }
                    >
                      {t('paragraph.description')}
                    </Link>
                  ),
                }}
              />
            </Typography>
          </Grid>
        );
      }
    );

    return sectionTexts;
  };

  return (
    <div
      key={localStorage.getItem('lang')}
      style={{
        position: 'relative',
        background: 'linear-gradient(to bottom, #F8FAFC, #FFFFFF)',
        padding: '24px',
        paddingInline: '0px',
      }}
    >
      <ReturnButton
        style={{ position: 'relative', marginBottom: '24px' }}
        onClick={() => {
          trackEvent({
            event: 'Button click',
            action: 'Back',
          });
          window.history.replaceState(null, '', '/');
          onClose();
        }}
      />
      <Typography
        variant="h1"
        sx={{
          marginBottom: '24px',
          fontSize: '26px',
          fontWeight: '700',
          lineHeight: '32px',
        }}
      >
        {tupload('termsOfService')}
      </Typography>
      {PolicyParagraps.map((paragraph: any, index: number) => {
        return (
          <div
            style={{
              background: 'white',
              padding: '24px',
              borderRadius: '10px',
              marginBottom: '16px',
              boxShadow: '0px 4px 30px 1px rgba(0, 55, 129, 0.03)',
            }}
          >
            <Typography
              sx={{
                fontSize: '20px',
                fontWeight: '700',
                lineHeight: '34px',
                marginBottom: '8px',
              }}
            >
              {tupload('termsOfServices.articles.' + index + '.title')}
            </Typography>
            <Typography
              sx={{
                fontSize: '16px',
                fontWeight: '500',
                lineHeight: '27.2px',
                color: '#9CA0AB',
              }}
            >
              <Trans
                i18nKey={'termsOfServices.articles.' + index + '.description'}
                t={tupload}
                components={{
                  br: <br />,
                  phone: (
                    <a
                      style={{ textDecoration: 'none', color: '#9CA0AB' }}
                      href={
                        'tel:' +
                        tupload(
                          'termsOfServices.articles.' + index + '.description'
                        ).replace(' ', '')
                      }
                    >
                      {tupload(
                        'termsOfServices.articles.' + index + '.description'
                      )}
                    </a>
                  ),
                  email: (
                    <a
                      style={{ color: '#0179FF' }}
                      color="#0179FF"
                      href={
                        'mailto:' +
                        tupload(
                          'termsOfServices.articles.' + index + '.description'
                        )
                      }
                    >
                      {tupload(
                        'termsOfServices.articles.' + index + '.description'
                      )}
                    </a>
                  ),
                }}
              />
            </Typography>
          </div>
        );
      })}
      <Typography
        sx={{
          fontSize: '16px',
          fontWeight: '500',
          lineHeight: '27.2px',
          color: '#9CA0AB',
          textAlign: 'center',
          marginInline: '50px',
          marginTop: '16px',
        }}
      >
        <Trans
          i18nKey={'termsOfServices.articles.footer'}
          t={tupload}
          components={{
            email: (
              <a
                style={{ color: '#0179FF' }}
                color="#0179FF"
                href={'mailto:' + tupload('termsOfServices.articles.footer')}
              >
                {tupload('termsOfServices.articles.footer')}
              </a>
            ),
          }}
        />
      </Typography>
    </div>
  );
}

export default TermsConditions;
const PolicyParagraps = [
  {
    title: '0.title',
    description: '0.description',
  },
  {
    title: '1.title',
    description: '1.description',
  },
  {
    title: '2.title',
    description: '2.description',
  },
];
