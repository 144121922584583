import {
  AllianzInsuranceService,
  Quote,
  Item,
  Receipt,
  PolicyForm,
  UserInfo,
} from '@zenown-insurance/services/requests';
import _, { set } from 'lodash';
import { useHistory, useLocation } from 'react-router-dom';

import { v4 as uuid } from 'uuid';
import { createContext, useState, useEffect } from 'react';
import HowDoesWorks from '../components/zenown-insurance/howDoesWorks';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import { useTranslation } from 'react-i18next';
import { namespaces } from '@zenown-insurance/i18n-service';
import Resizer from 'react-image-file-resizer';
import { loadStripe, Stripe } from '@stripe/stripe-js';
import { Button, Dialog, DialogContent, Grid, Typography } from '@mui/material';
import ReturnButton from '../components/return-button/ReturnButton';
import PhoneGrab from '../assets/insurance/phone-grab.png';
import PhoneGrab2 from '../assets/insurance/phone-grab@2x.png';
import PhoneGrab3 from '../assets/insurance/phone-grab@3x.png';
import PrimaryButton from '../components/primary-button/PrimaryButton';
import Pusher from 'pusher-js';
import { AnalyticsService } from '@zenown-insurance/services/requests';
import PolicyPage from '../components/zenown-insurance/PolicyPage';
import TermsConditions from '../components/zenown-insurance/TermsConditions';
const stripeKey = process.env['NX_STRIPE_KEY'] + '';
console.log('stripeKey', stripeKey);
const analyticsService = new AnalyticsService();
export function calculatePremium(price: number) {
  // premium is 1% of the price plus 5% of that premium
  let premium = price * 0.0105;

  return Math.max(Math.round(premium * 10) / 10, 30.0);
}
export type ProcessStepType =
  | ''
  | 'upload'
  | 'watchpictureupload'
  | 'watchserialupload'
  | 'survey'
  | 'processing'
  | 'quoteProposition'
  | 'payementForm'
  | 'legalForm'
  | 'checkout'
  | 'payementCompleted'
  | 'processingSerial';

interface InsureProductProcessContextInterface {
  currentProcessStep: ProcessStepType | undefined;
  isLoading: boolean;
  clientSecret?: string;
  receipt?: Receipt;
  file?: any;
  item?: Item;
  quote?: Quote;
  userInfo?: UserInfo;
  serialNumber?: string;
  serialFile: any;
  errorMessage?: string | undefined;
  quoteOptions?: {
    id: number;
    name: string;
    price: number;
    premium: number;
  }[];
  simulateAdmin: boolean;
  progress?: number | undefined;
  setProgress: (v: any) => void;
  policyId?: string;
  stripe: any;
  elements: any;

  InitProcess: (askForConfirmation?: boolean) => void;

  showHowItWorks: () => void;
  uploadFile: (e: any) => Promise<void>;
  submitFile: () => void;
  updateProcessingStatus: (b?: boolean) => void;
  setQuote: (q: any) => void;
  submitQuote: () => void;
  insureProduct: (userInfo: UserInfo) => Promise<void>;
  setErrorMessage: (v: string) => void;
  passToPremium: (v: boolean) => void;
  setCurrentProcessStep: (v: ProcessStepType) => void;
  setIsLoading: (v: boolean) => void;
  setSimulateAdmin: (v: boolean) => void;
  setItem: (v: any) => void;
  setQuoteOptions: (v: any) => void;
  setOpenConfirmationPopUP: (v: any) => void;
  tcommon: any;
  tPayementCompleted: any;
  tProcessing: any;
  tQuoteProposition: any;
  tUserForm: any;
  tUpload: any;
  tHow: any;
  setSerialNumber: (v: any) => void;
  setSerialFile: (v: any) => void;
}

const InsureProductProcessContext = createContext<
  InsureProductProcessContextInterface | any
>({
  currentProcessStep: undefined,
  setOpenConfirmationPopUP: (v: any) => {},
  simulateAdmin: false,
  isLoading: false,
  quoteOptions: [],
  progress: undefined,
  setProgress: (v: any) => {},
  policyId: undefined,
  stripe: undefined,
  elements: undefined,
  InitProcess: () => {},
  showHowItWorks: () => {},
  uploadFile: async () => {},
  submitFile: () => {},
  updateProcessingStatus: () => {},
  setQuote: (q: any) => {},
  submitQuote: () => {},
  insureProduct: async (userinfo: UserInfo) => {},
  setErrorMessage: (v: string) => {},
  passToPremium: (v: boolean) => {},
  setCurrentProcessStep: (v: ProcessStepType) => {},
  setIsLoading: (v: boolean) => {},
  setSimulateAdmin: (v: boolean) => {},
  setItem: (q: any) => {},
  setQuoteOptions: (q: any) => {},
  tcommon: undefined,
  tPayementCompleted: undefined,
  tProcessing: undefined,
  tQuoteProposition: undefined,
  tUserForm: undefined,
  tUpload: undefined,
  tHow: undefined,
  serialNumber: undefined,
  setSerialNumber: (v: any) => {},
  serialFile: undefined,
  setSerialFile: (v: any) => {},
});

export const InsureProductProcessContextProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const location = useLocation();
  const { i18n } = useTranslation(namespaces.pages.uplaodPage);
  const [showTerms, setShowTerms] = useState<boolean>(false);
  useEffect(() => {
    if (!i18n) return;
    console.log("location.pathname === 'terms-of-service'", location.pathname);
    if (location.pathname[location.pathname.length - 1] === '/')
      location.pathname = location.pathname.slice(0, -1);
    if (location.pathname === '/terms-of-service/de') {
      localStorage.setItem('lang', 'de');
      i18n.changeLanguage('de');
      localStorage.setItem('i18nextLng', 'de');
      setTimeout(() => {
        setShowTerms(true);
        history.replaceState(
          null,
          i18n.t('termsOfService'),
          '/terms-of-service/de'
        );
      }, 0);
    }
    if (location.pathname === '/terms-of-service/fr') {
      localStorage.setItem('lang', 'fr');
      i18n.changeLanguage('fr');
      localStorage.setItem('i18nextLng', 'fr');
      setTimeout(() => {
        setShowTerms(true);
        history.replaceState(
          null,
          i18n.t('termsOfService'),
          '/terms-of-service/fr'
        );
      }, 0);
    }
    if (location.pathname === '/terms-of-service/it') {
      localStorage.setItem('lang', 'it');
      i18n.changeLanguage('it');
      localStorage.setItem('i18nextLng', 'it');
      setTimeout(() => {
        setShowTerms(true);
        history.replaceState(
          null,
          i18n.t('termsOfService'),
          '/terms-of-service/it'
        );
      }, 0);
    }
    if (
      location.pathname === '/terms-of-service/en' ||
      location.pathname === '/terms-of-service'
    ) {
      localStorage.setItem('lang', 'eb');
      i18n.changeLanguage('en');
      localStorage.setItem('i18nextLng', 'en');
      setTimeout(() => {
        setShowTerms(true);
        history.replaceState(
          null,
          i18n.t('termsOfService'),
          '/terms-of-service/en'
        );
      }, 0);
    }
  }, [location, i18n]);

  const [outWorkHours, setOutWorkHours] = useState(false);
  const [receiptRejectedCount, setReceiptRejectedCount] = useState<any>(0);
  useEffect(() => {
    const lang: string = localStorage.getItem('lang')
      ? localStorage.getItem('lang') + ''
      : 'en';
    localStorage.setItem('i18nextLng', lang);
  });
  const [serialAccepted, setSerialAccepted] = useState<any>(
    Boolean(localStorage.getItem('serialAccepted') && false) || undefined
  );
  const [intialPremium, setIntialPremium] = useState<any>(
    localStorage.getItem('intialPremium') || undefined
  );
  const [acceptedPhotos, setAcceptedPhotos] = useState<any>(
    localStorage.getItem('acceptedPhotos')
      ? JSON.parse(localStorage.getItem('acceptedPhotos') || '')
      : []
  );
  const [checkingPhotos, setCheckingPhotos] = useState(
    localStorage.getItem('checkingPhotos') + '' === 'true'
  );
  const [applePayPaymentRequest, setApplePayPaymentRequest] = useState<any>();
  const [paymentParams, setPaymentParams] = useState<any>();
  const [serialFile, setSerialFile] = useState<any>();
  const [surveyAnswers, setSurveyAnswers] = useState<any>(
    localStorage.getItem('surveyAnswers') || undefined
  );
  const [calculatorModalOpen, setCalculatorModalOpen] = useState(true);
  const [watchPicture, setWatchPicture] = useState<any>();
  const [watchSerialPicture, setSerialWatchPicture] = useState<any>();
  const [watchCardPicture, setSerialCardPicture] = useState<any>();

  const [apiErrorMessage, setApiErrorMessage] = useState<any>();
  const [serialNumber, setSerialNumber] = useState<any>(
    localStorage.getItem('serialNumber') || undefined
  );
  const [pusher, setPusher] = useState<any>();
  const { t: tPayementCompleted } = useTranslation(
    namespaces.pages.payementCompletedPage
  );
  const { t: tcommon } = useTranslation(namespaces.common);
  const { t: tProcessing } = useTranslation(namespaces.pages.processingPage);
  const { t: tQuoteProposition } = useTranslation(
    namespaces.pages.quotePropositionPage
  );
  const { t: tSerial } = useTranslation(namespaces.pages.serialNumberPage);
  const { t: tHow } = useTranslation(namespaces.pages.howPage);
  const { t: tUserForm } = useTranslation(namespaces.pages.UserFormPage);
  const { t: tUpload } = useTranslation(namespaces.pages.uplaodPage);

  const [progress, setProgress] = useState<undefined | number>();
  const [stripe, setStripe] = useState<any>();
  const [elements, setElements] = useState<any>();
  const [policyId, setPolicyId] = useState<string>();
  const [clientSecret, setClientSecret] = useState<string | undefined>();

  const [simulateAdmin, setSimulateAdmin] = useState<boolean>(false);
  const [file, setFile] = useState<string | Blob | any>([]);
  const [showHowWorks, setShowHowWorks] = useState<boolean>(false);
  const [showPolicy, setShowPolicy] = useState<boolean>(false);

  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [receipt, setReceipt] = useState<Receipt | undefined>(
    JSON.parse(localStorage.getItem('receipt') as any) || undefined
  );
  const [item, setItem] = useState<Item | undefined>(
    JSON.parse(localStorage.getItem('item') as any) || undefined
  );
  const [quote, setQuote] = useState<Quote | undefined>(
    JSON.parse(localStorage.getItem('quote') as any) || undefined
  );
  const [openConfirmationPopUP, setOpenConfirmationPopUP] = useState(false);
  useEffect(() => {
    if (openConfirmationPopUP) {
      trackEvent({
        event: 'Button click',
        action: 'Exit',
      });
      trackEvent({
        event: 'Pop up',
        action: 'Are you sure you want to cancel?',
      });
    }
  }, [openConfirmationPopUP]);
  const [quoteOptions, setQuoteOptions] = useState<
    {
      id: number;
      name: string;
      price: number;
      premium: number;
    }[]
  >(JSON.parse(localStorage.getItem('quoteOptions') as any) || []);
  const [userInfo, setUserInfo] = useState<UserInfo | undefined>(
    JSON.parse(localStorage.getItem('userInfo') as any) || undefined
  );
  const [errorMessage, setErrorMessage] = useState<string | undefined>();
  const [uploadUrls, setUploadUrls] = useState<{
    preview: string;
    original: string;
  }>({
    preview: '',
    original: '',
  });
  useEffect(() => {
    const redirectToPayement = async (
      id: string,
      policy_id: string,
      premium: boolean
    ) => {
      setIsLoading(true);
      //setCurrentProcessStep('payementCompleted');
      const embeddedInsuranceService = new AllianzInsuranceService();
      embeddedInsuranceService
        .getProductStatus(id)
        .then(async (receiptData) => {
          console.log('getting  product status :', receiptData);
          if (
            receiptData.statusCode === 1 &&
            receiptData?.data &&
            receiptData?.data?.products?.length
          ) {
            setItem(receiptData?.data.products[0] as Item);
            const newQuote = {
              uid: receiptData?.data?.products[0].premiums[0].id,
              price: receiptData?.data?.products[0].premiums[0].premium,
              currency: receiptData?.data?.products[0].currency,
              premiumPrice:
                receiptData?.data?.products[0].premiums[1].premium -
                receiptData?.data?.products[0].premiums[0].premium,
              premium,
            };

            setQuote(newQuote as Quote);
            setQuoteOptions(receiptData?.data?.products[0].premiums);
            setCurrentProcessStep('payementCompleted');
            localStorage.setItem('currentProcessStep', 'payementCompleted');
          } else if (
            receiptData.data?.rejection &&
            receiptData?.data?.status === 'rejected'
          ) {
            InitProcess();
          }
        })
        .catch((err) => {
          InitProcess();
        });

      setIsLoading(false);
    };
    const payementData = JSON.parse(localStorage.getItem('payementData') + '');
    if (payementData?.receiptId && payementData?.policyId) {
      localStorage.setItem('currentProcessStep', 'payementCompleted');
      redirectToPayement(
        payementData.receiptId,
        payementData.policyId,
        payementData.premium === true || payementData.premium === 'true'
      );
    } else {
      if (!receipt) getReceipt();
      else {
        setIsLoading(false);
      }
    }
  }, []);

  async function constraintImage(
    d: any,
    quality = 100,
    drop = 1
  ): Promise<any> {
    // console.log('-- Resize method called - see if we need to resize')
    const resizeFile = async (d: any) =>
      new Promise(async (resolve) => {
        try {
          Resizer.imageFileResizer(
            d[0]?.file,
            3500,
            3500,
            'JPEG',
            quality - drop,
            0,
            async (uri: any) => {
              // console.log('-- Resize is needed')
              const contentType = uri
                .split(';base64,')[0]
                ?.replace('data:', '');
              const namesArray = d[0]?.file?.name.split('.');
              namesArray.pop();
              namesArray.push('jpg');

              // console.log(`😎😎 Quality ${quality - drop}`);

              const newName = 'COMPRESSED-' + namesArray.join('.');
              d = [
                {
                  data_url: uri,
                  file: new File([uri], newName, { type: contentType }),
                },
              ];

              resolve(d);
            },
            'blob ',
            1000,
            1000
          );
        } catch (err) {
          console.log('---- Error encountered');
          console.log(err);
        }
      });
    let result: any;
    if (d && d.length) {
      //TODO: if a condition on size then we resize
      console.log('File size: ' + d[0]?.file?.size / 1024 / 1024 + ' mb');
      if (d[0]?.file?.size / 1024 / 1024 > 3.5) {
        // console.log('--- Resize iz needed!');
        result = await resizeFile(d);

        // console.log('--- Got the resize, file is now: ' + (result[0]?.file?.size / 1024 / 1024) + ' mb');

        //recursive call until we get below the threshold
        if (result[0]?.file?.size / 1024 / 1024 > 3.5) {
          return constraintImage(d, 100, drop + 1);
        }
      } else {
        // console.log('--- Resize iz not needed');
        result = d;
      }
      if (result) {
        return result;
        //TODO: do recursive here till u have udner 3
      } else {
        console.log('--WE FAILED!!!');
      }
    }
  }

  const uploadFile = async (e: any) => {
    try {
      setErrorMessage('');
      console.log('e ', e);

      if (!e || !e.length) {
        setFile(undefined);
        await InitProcess();
        return;
      } else {
        setProgress(1);

        if (e[0]?.file) {
          const embeddedInsuranceService = new AllianzInsuranceService();
          const newFile = await constraintImage(e, 100, 1);
          setFile(newFile);
          try {
            let binaryFile: any;
            await fetch(newFile[0].data_url).then(
              async (res) => (binaryFile = await res.blob())
            );
            const options = {
              onUploadProgress: (progressEvent: any) => {
                const { loaded, total } = progressEvent;
                let percent = Math.floor((loaded * 100) / total);

                setProgress(percent);
              },
            };

            await embeddedInsuranceService.uploadFile(
              receipt?.uploadURL + '',
              binaryFile,
              options
            );
            const notification: any =
              await embeddedInsuranceService.notifyWithTheUpload(
                receipt?.uid + ''
              );
            //here we r gonna simulate the admin behavior by apis

            if (!notification.error && !notification?.error?.length) {
              localStorage.setItem('receipt', JSON.stringify(receipt));

              initPusher();
              await submitFile();
            } else {
              await InitProcess();
              setErrorMessage(tcommon('generalError'));
              setTimeout(() => {
                setErrorMessage('');
              }, 10000);
            }
            setTimeout(() => {
              setProgress(undefined);
            }, 1000);
          } catch (err) {
            setErrorMessage(tcommon('generalError'));
            setReceiptRejectedCount(receiptRejectedCount + 1);
            setCurrentProcessStep('upload');
            setProgress(undefined);
          }
        } else {
          setErrorMessage(undefined);
        }
      }
    } catch (err) {
      console.log('errrrrrro', err);
    }
  };
  const getReceipt = async () => {
    setIsLoading(true);
    const embeddedInsuranceService = new AllianzInsuranceService();
    const receiptData = await embeddedInsuranceService.getReceipt();

    if (receiptData?.data) {
      if (localStorage.getItem('receiptTimeout')) {
        let oldTimeout: any = localStorage.getItem('receiptTimeout') + '';
        clearTimeout(+oldTimeout);
      }

      setReceipt(receiptData.data);
      let receiptTimeout = setTimeout(async () => {
        if (
          localStorage.getItem('currentProcessStep') === 'upload' ||
          currentProcessStep === 'upload'
        ) {
          await getReceipt();
        }
      }, 1000 * 60 * 5);
      localStorage.setItem('receiptTimeout', receiptTimeout + '');
    } else {
      setErrorMessage(receiptData.error);
      setCurrentProcessStep('upload');
    }

    setIsLoading(false);
  };

  const listenOnSerial = async (successFunction: any, errorFunction: any) => {
    if (pusher && receipt?.uid) {
      pusher.unsubscribe(receipt?.uid + '');
    }

    const p = new Pusher('475a0584d1687dd42b4a', {
      cluster: 'eu',
    });
    setPusher(p);

    const channel = p.subscribe(receipt?.uid + '');

    channel.bind('serial-input-change', successFunction);
    channel.bind('serial-rejected', errorFunction);
  };

  const submitFile = async () => {
    try {
      localStorage.setItem('receipt', JSON.stringify(receipt));
      if (!quote) {
        setCurrentProcessStep('survey');
        localStorage.setItem('currentProcessStep', 'processing');
        //initPusher();
      } else {
        if (intialPremium !== undefined) {
          setCurrentProcessStep('quoteProposition');
        } else {
          setCurrentProcessStep('survey');
        }
        localStorage.setItem('currentProcessStep', 'quoteProposition');
        localStorage.setItem('quote', JSON.stringify(quote));
        localStorage.setItem('quoteOptions', JSON.stringify(quoteOptions));
      }
      if (localStorage.getItem('receiptTimeout')) {
        let oldTimeout: any = localStorage.getItem('receiptTimeout') + '';
        clearTimeout(+oldTimeout);
      }
    } catch (err) {
      tcommon('generalError');
      setCurrentProcessStep('upload');
      setProgress(undefined);
    }
  };

  async function initializeAppleGooglePay(params: any, stripe: Stripe) {
    //Apple and Google pay integration

    const p = {
      country: params.country,
      currency: params.currency,
      total: {
        label: params.label,
        amount: Math.floor(params.amount),
      },
    };

    try {
      const paymentRequest = stripe.paymentRequest(p);

      console.log('😎 Params sent to paymentRequest');
      console.log(p);

      if (await paymentRequest.canMakePayment()) {
        console.log('😎 Payment request setup for Apple/Google pay');
        setApplePayPaymentRequest(paymentRequest);
      } else {
        console.log('Device does not have Apple/Google pay available');
        setApplePayPaymentRequest(undefined);
      }
    } catch (e) {
      console.log('❌ CheckAppleGooglePay err: ', e);
    }
  }

  async function updateProcessingStatus(run = false, changeCurrentStep = true) {
    if (!receipt?.uid) {
      return;
    }
    if (simulateAdmin && run) {
      setTimeout(
        async () =>
          await embeddedInsuranceService.stimulateAdminBehavior({
            uid: receipt.uid,
            name: 'E-SPARTAN-E-ENDURO',
            brand: 'devinci',
            price: 1700,
            currency: 'eur',
            purchase_date: '2020-01-01',
          }),
        0
      );
    }

    const embeddedInsuranceService = new AllianzInsuranceService();
    embeddedInsuranceService
      .getProductStatus(receipt?.uid)
      .then(async (receiptData) => {
        console.log('getting  product data :', receiptData);
        if (
          receiptData.statusCode === 1 &&
          receiptData?.data &&
          receiptData?.data?.products?.length
        ) {
          setItem(receiptData?.data.products[0] as Item);
          setReceiptRejectedCount(0);
          localStorage.removeItem('lastFailed');
          localStorage.setItem(
            'item',
            JSON.stringify(receiptData?.data.products[0])
          );
          const newQuote = {
            uid: receiptData?.data?.products[0].premiums[0].id,
            price: receiptData?.data?.products[0].premiums[0].premium,
            currency: receiptData?.data?.products[0].currency,
            premium: intialPremium,
            premiumPrice:
              receiptData?.data?.products[0].premiums[1].premium -
              receiptData?.data?.products[0].premiums[0].premium,
          };
          setQuote(newQuote as Quote);
          setQuoteOptions(receiptData?.data?.products[0].premiums);
          localStorage.setItem('quote', JSON.stringify(newQuote));
          localStorage.setItem(
            'quoteOptions',
            JSON.stringify(receiptData?.data?.products[0].premiums)
          );
          if (
            changeCurrentStep &&
            (currentProcessStep === 'processing' ||
              currentProcessStep === 'survey')
          ) {
            console.log(
              'we r getting pinged',
              'currentProcessStep',
              currentProcessStep,
              'newQuote',
              newQuote
            );
            if (currentProcessStep === 'processing')
              setCurrentProcessStep('quoteProposition');

            localStorage.setItem('currentProcessStep', 'quoteProposition');
          }

          /**/

          return;
        } else if (
          receiptData.data?.rejection &&
          receiptData?.data?.status === 'rejected'
        ) {
          await InitProcess();
          trackEvent({
            event: 'Response',
            action: 'Image not recognised please try again',
          });
          setErrorMessage(tcommon('rejectionError'));
          setReceiptRejectedCount(receiptRejectedCount + 1);
          setTimeout(() => {
            setErrorMessage('');
          }, 10000);
        } else if (simulateAdmin) {
          setTimeout(async () => await updateProcessingStatus(), 0);
        }
      })
      .catch((err) => {
        setErrorMessage(tcommon('rejectionError'));
        setReceiptRejectedCount(receiptRejectedCount + 1);
        setReceipt(undefined);
        setCurrentProcessStep('upload');
      });
  }
  function initPusher() {
    if (pusher && receipt?.uid) {
      pusher.unsubscribe(receipt?.uid + '');
    }
    if (simulateAdmin) {
      updateProcessingStatus(true);
    } else {
      const p = new Pusher(process.env['NX_PUSHER_ID'] + '', {
        cluster: 'eu',
      });
      console.log('we r trying to set pusher  for receipt', receipt?.uid);
      setPusher(p);

      const channel = p.subscribe(receipt?.uid + '');
      console.log('channel ', channel);
      channel.bind('event', async function (data: any) {
        const { event, receipt, photo, name } = data;
        console.log('{event,receipt,photo,name} ', {
          event,
          receipt,
          photo,
          name,
        });
        if (event === 'receipt-rejected') {
          await InitProcess();
          trackEvent({
            event: 'Response',
            action: 'Image not recognised please try again',
          });
          setErrorMessage(tcommon('rejectionError'));
          setTimeout(() => {
            setErrorMessage('');
          }, 10000);
        } else if (event === 'photo-approved') {
          console.log(
            '----- ',
            localStorage.getItem('acceptedPhotos'),
            localStorage.getItem('checkingPhotos')
          );
          let data = localStorage.getItem('acceptedPhotos')
            ? JSON.parse(localStorage.getItem('acceptedPhotos') + '')
            : [false, false];
          console.log('approval', photo?.type + ' fdf');
          if (photo?.type === 0) {
            data[0] = true;
            setAcceptedPhotos(data);
            localStorage.setItem('acceptedPhotos', JSON.stringify(data));
          } else {
            data[1] = true;
            setAcceptedPhotos(data);
            localStorage.setItem('acceptedPhotos', JSON.stringify(data));
          }
          console.log(
            "' localStorage.getItem('checkingPhotos') '" +
              localStorage.getItem('checkingPhotos') +
              '' ===
              'true' &&
              data[0] &&
              data[1]
          );
          if (
            localStorage.getItem('checkingPhotos') + '' === 'true' &&
            data[0] &&
            data[1]
          ) {
            // setCheckingPhotos(false);
            //localStorage.setItem('checkingPhotos', 'false');
            // await insureProduct();
          }
        } else if (event === 'photo-rejected') {
          console.log('rejection', photo?.type + ' fdf');
          if (photo?.type === 0) {
            setWatchPicture(undefined);
            setTimeout(() => {
              setCurrentProcessStep('watchpictureupload');
            }, 0);
            setErrorMessage(tcommon('rejectionError.0'));
          } else {
            setSerialWatchPicture(undefined);
            setSerialCardPicture(undefined);
            setTimeout(() => {
              setCurrentProcessStep('watchserialupload');
            }, 0);
            setErrorMessage(tcommon('rejectionError.' + photo?.type));
          }
        } else if (event === 'product-added') {
          setItem(receipt as Item);
          localStorage.setItem('item', JSON.stringify(receipt));

          const newQuote = {
            uid: '0',
            price: calculatePremium(receipt.price),
            currency: 'CHF',
            premium: intialPremium,
            premiumPrice: 0,
          };
          setQuote(newQuote as Quote);
          setQuoteOptions([]);
          localStorage.setItem('quote', JSON.stringify(newQuote));
          localStorage.setItem('quoteOptions', JSON.stringify([]));
          if (
            localStorage.getItem('currentProcessStep') === 'processing' ||
            (localStorage.getItem('currentProcessStep') === 'survey' &&
              (currentProcessStep === 'processing' ||
                currentProcessStep === 'survey'))
          ) {
            console.log(
              'we r getting pinged',
              'currentProcessStep',
              currentProcessStep,
              'newQuote',
              newQuote
            );
            if (currentProcessStep === 'processing')
              setCurrentProcessStep('quoteProposition');

            localStorage.setItem('currentProcessStep', 'quoteProposition');
          }
        }
        if (false) {
          updateProcessingStatus(
            false,
            localStorage.getItem('currentProcessStep') === 'processing' ||
              localStorage.getItem('currentProcessStep') === 'survey'
          );
        }
      });
    }
  }

  const [currentProcessStep, setCurrentProcessStep] = useState<
    ProcessStepType | undefined
  >(
    JSON.parse(localStorage.getItem('payementData') + '')
      ? 'payementCompleted'
      : (localStorage.getItem('currentProcessStep') as any) || 'upload'
  );

  const passToPremium = (premium: boolean) => {
    if (quote) {
      setQuote({ ...quote, premium });
      localStorage.setItem('quote', JSON.stringify({ ...quote, premium }));
    }
  };

  const InitProcess = async () => {
    const cookies = localStorage.getItem('cookies_store');
    localStorage.clear();
    if (cookies) localStorage.setItem('cookies_store', cookies + '');

    if (pusher) {
      pusher.unsubscribe(receipt?.uid + '');
    }
    setTimeout(() => {
      setOutWorkHours(false);
      setSerialNumber(undefined);
      setSerialAccepted(undefined);
      setSerialFile(undefined);
      setSerialNumber('');
      setReceipt(undefined);
      setQuote(undefined);
      setItem(undefined);
      setFile(undefined);
      setUserInfo(undefined);
      setWatchPicture(undefined);
      setSerialCardPicture(undefined);
      setSerialWatchPicture(undefined);
      setErrorMessage('');
      setAcceptedPhotos([]);
      setCheckingPhotos(false);

      setCurrentProcessStep('upload');
    }, 0);

    await getReceipt();
  };
  const submitQuote = async () => {
    setCurrentProcessStep('payementForm');
  };

  const registerUser = async (user: UserInfo, dirtyFields?: any) => {
    setErrorMessage('');
    setUserInfo(user);
    localStorage.setItem('userInfo', JSON.stringify(user));
    setCurrentProcessStep('legalForm');
    /*
    setCurrentProcessStep('serial');
    if (serialAccepted) {
      setCurrentProcessStep('legalForm');
    } else {
      setCurrentProcessStep('serial');
    }*/
    return;
  };
  const insureProduct = async () => {
    console.log('acceptedPhotos', acceptedPhotos);
    if (
      !(acceptedPhotos?.length === 2 && acceptedPhotos[0] && acceptedPhotos[1])
    ) {
      console.log('waiting for approval');
      setCheckingPhotos(true);
      localStorage.setItem('checkingPhotos', 'true');
      return;
    }
    console.log('waiting for approval');

    setIsLoading(true);
    if (
      /*currentProcessStep !== 'payementForm' ||*/ !quote ||
      !item ||
      !receipt
    )
      return;
    const embeddedInsuranceService = new AllianzInsuranceService();

    return embeddedInsuranceService
      .createPolicy({
        ...userInfo,
        uid: receipt?.uid,

        lang: localStorage.getItem('i18nextLng'),
        language: localStorage.getItem('i18nextLng'),
      } as any)
      .then(async (result) => {
        console.log('😎 creating policy :', result);
        //setErrorMessage('');
        //setUserInfo(user);
        //localStorage.setItem('userInfo', JSON.stringify(user));

        setPolicyId(result.data?.policy_id);

        return await embeddedInsuranceService
          .getClientSecret({ policy_id: result.data?.policy_id })
          .then(async (res) => {
            console.log('😎 getting client secret :', res);
            const { clientSecret, country, label, currency, amount } = res;
            const stripeClient = await loadStripe(stripeKey);
            setStripe(stripeClient);

            if (stripeClient == null) {
              console.log('😎 Stripe client is NULL');
              return;
            }

            console.log('😎 Stripe has been setup with client secret');

            setClientSecret(clientSecret);

            const params = {
              country: country,
              label: label,
              currency: currency.toLowerCase(),
              amount: amount,
            };

            setPaymentParams(params);

            await initializeAppleGooglePay(params, stripeClient);

            setErrorMessage('');

            setTimeout(() => setCurrentProcessStep('checkout'), 0);
            setTimeout(() => setIsLoading(false), 0);
          })
          .catch((err) => {
            setTimeout(() => setIsLoading(false), 0);
            console.log('ERR in getting client secret ', err);
            throw err;
          });
      })
      .catch((err) => {
        console.log('😎 error policy :');
        setApiErrorMessage(err?.error || err);
        setIsLoading(false);
        setCurrentProcessStep('payementForm');
        console.log('ERR in creating policy ', err);
      });
  };

  const showHowItWorks = () => setShowHowWorks(true);

  useEffect(() => {
    if (
      currentProcessStep === 'processing' ||
      currentProcessStep === 'survey'
    ) {
      initPusher();
    }
  }, [currentProcessStep]);

  const [payementCancelDialog, setPayementCancelDialog] = useState(false);

  useEffect(() => {
    function onBackButtonEvent(e: any) {
      e.preventDefault();
      if (localStorage.getItem('currentProcessStep') === 'payementCompleted') {
        InitProcess();
      }
      let step = currentProcessStep;

      if (step === 'upload') {
        window.history.back();
      } else if (step === 'payementCompleted') {
        InitProcess();
      } else if (
        step === 'quoteProposition' ||
        step === 'processing' ||
        step === 'survey'
      ) {
        setOpenConfirmationPopUP(true);
      } else {
        trackEvent({
          event: 'Button click',
          action: 'Back',
        });
        if (step === 'payementForm') {
          setCurrentProcessStep('quoteProposition');
        } else if (step === 'legalForm') {
          setCurrentProcessStep('payementForm');
        } else if (step === 'checkout') {
          setPayementCancelDialog(true);
        } else {
          window.history.back();
        }
      }
    }
    window.history.pushState(null, '', window.location.pathname);
    window.addEventListener('popstate', onBackButtonEvent);
    return () => {
      window.removeEventListener('popstate', onBackButtonEvent);
    };
  }, [currentProcessStep]);

  const trackEvent = async (data: {
    event: string;
    action: string;
    label?: string;
  }) => {
    return await analyticsService.tractEvent({
      project_id: 'WATCHES_INSURANCE_APP',
      journey_id: '',
      event: data?.event,
      action: data?.action,
      label: data?.label,
    });
  };
  if (showTerms) {
    return (
      <TermsConditions
        trackEvent={trackEvent}
        onClose={() => {
          setShowTerms(false);
        }}
      />
    );
  }
  if (showPolicy) {
    return (
      <PolicyPage
        trackEvent={trackEvent}
        onClose={() => {
          setShowPolicy(false);
        }}
      />
    );
  }
  if (showHowWorks) {
    return (
      <HowDoesWorks
        trackEvent={trackEvent}
        setShowPolicy={setShowPolicy}
        setShowTerms={setShowTerms}
        onClose={() => {
          setShowHowWorks(false);
        }}
      />
    );
  }

  return (
    <InsureProductProcessContext.Provider
      value={{
        acceptedPhotos,
        outWorkHours,
        setOutWorkHours,
        setAcceptedPhotos,
        checkingPhotos,
        setCheckingPhotos,
        calculatorModalOpen,
        setShowPolicy,
        setShowTerms,
        watchPicture,
        setWatchPicture,
        watchSerialPicture,
        setSerialWatchPicture,
        watchCardPicture,
        setSerialCardPicture,
        trackEvent,
        surveyAnswers,
        setSurveyAnswers,
        payementCancelDialog,
        setPayementCancelDialog,
        tcommon,
        tPayementCompleted,
        tProcessing,
        tQuoteProposition,
        tUserForm,
        tUpload,
        tHow,
        setOpenConfirmationPopUP,
        userInfo,
        applePayPaymentRequest,
        setApplePayPaymentRequest,
        paymentParams,
        setPaymentParams,
        apiErrorMessage,
        setApiErrorMessage,
        simulateAdmin,
        stripe,
        elements,
        setFile,
        setSimulateAdmin,
        InitProcess,
        listenOnSerial,
        currentProcessStep,
        showHowItWorks,
        submitFile,
        file,
        receipt,
        uploadFile,
        isLoading,
        quoteOptions,
        progress,
        setProgress,
        updateProcessingStatus,
        item,
        quote,
        policyId,
        setQuote,
        submitQuote,
        insureProduct,
        errorMessage,
        clientSecret,
        setErrorMessage,
        passToPremium,
        setCurrentProcessStep,
        setIsLoading,
        setQuoteOptions,
        setItem,
        serialNumber,
        setSerialNumber,
        serialFile,
        setSerialFile,
        registerUser,
        tSerial,
        serialAccepted,
        setSerialAccepted,
        intialPremium,
        setIntialPremium,
        constraintImage,
        receiptRejectedCount,
        setReceiptRejectedCount,
      }}
    >
      <Backdrop
        sx={{
          color: '#fff',
          backgroundColor: 'transparent',
          zIndex: '9999',
          position: 'absolute',
          width: 'calc(100% + 30px)',
          left: '-15px',
        }}
        open={Boolean(isLoading)}
      >
        {false && <CircularProgress color="inherit" />}
      </Backdrop>
      <Dialog
        open={Boolean(checkingPhotos)}
        keepMounted
        PaperProps={{
          sx: {
            marginInline: '15px',
            width: '100%',
            borderRadius: '10px',
          },
        }}
        sx={{
          borderRadius: '10px',
          overflow: 'hidden',
          marginInline: '0px',
          width: '100%',
        }}
      >
        <ReturnButton
          style={{
            position: 'absolute',
            right: 0,
            marginRight: '15px',
            marginTop: '15px',
          }}
          close={true}
          onClick={() => {
            setCheckingPhotos(false);
            localStorage.setItem('checkingPhotos', 'false');
            //   setCurrentProcessStep('serial');
          }}
        />
        <DialogContent
          sx={{
            //maxHeight: '398px',
            paddingBottom: '24px',
            overflow: 'hidden',
            borderRadius: '10px',

            //minWidth: '345px',
            padding: '24px',
            marginBottom: '0px',
          }}
        >
          <Grid
            container
            direction="column"
            justifyContent="space-between"
            alignItems="center"
            sx={{
              marginTop: '17px',
              //minWidth: '345px',
              width: '100%',
              //paddingInline: '24px',
              marginBottom: '0px',
            }}
          >
            <Grid item sx={{ marginBottom: '20px' }}>
              <CircularProgress />
            </Grid>
            <Grid item>
              <Typography
                variant="h3"
                sx={{
                  marginBottom: '15px',
                  fontWeight: '600',
                  fontSize: '17px',
                  lineHeight: '23.22px',
                  letterSpacing: '-0.3px',

                  textAlign: 'center',
                }}
              >
                {tUpload('waitingForPictureapproval.title')}
              </Typography>
              <Typography
                color="#9CA0AB"
                sx={{
                  fontWeight: '500',
                  fontSize: '12px',
                  lineHeight: '18px',
                  letterSpacing: '-0.3px',
                  textAlign: 'center',
                }}
              >
                {tUpload('waitingForPictureapproval.description')}
              </Typography>
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
      <Dialog
        PaperProps={{
          sx: {
            marginInline: '15px',
            width: '100%',
            borderRadius: '10px',
          },
        }}
        sx={{
          borderRadius: '10px',
          overflow: 'hidden',
          marginInline: '0px',
          width: '100%',
        }}
        open={openConfirmationPopUP}
        keepMounted
        fullWidth
        maxWidth="sm"
        onClose={() => {
          trackEvent({
            event: 'Button click',
            action: 'Cancel',
          });
          setOpenConfirmationPopUP(false);
        }}
        aria-describedby="alert-dialog-slide-description"
      >
        <ReturnButton
          style={{
            position: 'absolute',
            right: 0,
            marginRight: '15px',
            marginTop: '15px',
          }}
          close={true}
          onClick={() => {
            trackEvent({
              event: 'Button click',
              action: 'Cancel',
            });
            setOpenConfirmationPopUP(false);
          }}
        />
        <DialogContent
          sx={{
            paddingBottom: '24px',
            borderRadius: '10px',
          }}
        >
          <Grid
            container
            direction="column"
            justifyContent="space-between"
            alignItems="center"
            sx={{
              marginTop: '30px',
              //marginBottom: '20px',
              width: '100%',
            }}
          >
            <Grid item>
              <img
                src={PhoneGrab}
                srcSet={`${PhoneGrab} 1x, ${PhoneGrab2} 2x, ${PhoneGrab3} 3x`}
                style={{
                  width: '110px',
                  height: '96px',
                }}
                alt="Phone Icon"
              />
            </Grid>
            <Grid item sx={{ paddingBottom: '15px', marginTop: '20px' }}>
              <Typography
                variant="h3"
                sx={{
                  fontWeight: '600',
                  display: 'flex',
                  justifyContent: 'center',
                  flexDirection: 'column',
                  textAlign: 'center',
                }}
              >
                {tcommon('cancelQuoteTitle')}
              </Typography>
            </Grid>
            <Grid item sx={{ marginBottom: '15px' }}>
              <Typography
                variant="caption"
                color="#9CA0AB"
                sx={{
                  fontWeight: '500',
                  display: 'flex',
                  justifyContent: 'center',
                  flexDirection: 'column',
                  textAlign: 'center',
                }}
              >
                {tcommon('cancelQuoteSubtitle')}
              </Typography>
            </Grid>
            <Grid item sx={{ marginBottom: '20px', width: '100%' }}>
              <PrimaryButton
                sx={{ fontStyle: 'Semibold S' }}
                onClick={() => {
                  trackEvent({
                    event: 'Button click',
                    action: 'Confirm',
                  });
                  InitProcess();
                  setOpenConfirmationPopUP(false);
                }}
              >
                {tcommon('confirm')}
              </PrimaryButton>
            </Grid>
            <Grid item>
              <Button
                variant="text"
                sx={{
                  marginBottom: '0px',
                  color: '#383A49',
                  padding: '0',
                  fontStyle: 'Semibold S',
                }}
                onClick={() => {
                  trackEvent({
                    event: 'Button click',
                    action: 'Cancel',
                  });
                  setOpenConfirmationPopUP(false);
                }}
              >
                {tcommon('cancel')}
              </Button>
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
      <Dialog
        PaperProps={{
          sx: {
            marginInline: '15px',
            width: '100%',
            borderRadius: '10px',
          },
        }}
        sx={{
          borderRadius: '10px',
          overflow: 'hidden',
          marginInline: '0px',
          width: '100%',
        }}
        open={payementCancelDialog}
        keepMounted
        fullWidth
        maxWidth="sm"
        onClose={() => setPayementCancelDialog(false)}
        aria-describedby="alert-dialog-slide-description"
      >
        <ReturnButton
          style={{
            position: 'absolute',
            right: 0,
            marginRight: '15px',
            marginTop: '15px',
          }}
          close={true}
          onClick={() => setPayementCancelDialog(false)}
        />
        <DialogContent
          sx={{
            paddingBottom: '24px',
            borderRadius: '10px',
          }}
        >
          <Grid
            container
            direction="column"
            justifyContent="space-between"
            alignItems="center"
            sx={{
              marginTop: '30px',
              //marginBottom: '20px',
              width: '100%',
            }}
          >
            <Grid item>
              <img
                src={PhoneGrab}
                srcSet={`${PhoneGrab} 1x, ${PhoneGrab2} 2x, ${PhoneGrab3} 3x`}
                style={{
                  width: '110px',
                  height: '96px',
                }}
                alt="Phone Icon"
              />
            </Grid>
            <Grid item sx={{ paddingBottom: '15px', marginTop: '20px' }}>
              <Typography
                variant="h3"
                sx={{
                  fontWeight: '600',
                  display: 'flex',
                  justifyContent: 'center',
                  flexDirection: 'column',
                  textAlign: 'center',
                }}
              >
                {tcommon('cancelPayementTitle')}
              </Typography>
            </Grid>
            <Grid item sx={{ marginBottom: '15px' }}>
              <Typography
                variant="caption"
                color="#9CA0AB"
                sx={{
                  fontWeight: '500',
                  display: 'flex',
                  justifyContent: 'center',
                  flexDirection: 'column',
                  textAlign: 'center',
                }}
              >
                {tcommon('cancelPayementDescription')}
              </Typography>
            </Grid>
            <Grid item sx={{ marginBottom: '20px', width: '100%' }}>
              <PrimaryButton
                sx={{ fontStyle: 'Semibold S' }}
                onClick={() => {
                  setCurrentProcessStep('legalForm');
                  setPayementCancelDialog(false);
                }}
              >
                {tcommon('confirm')}
              </PrimaryButton>
            </Grid>
            <Grid item>
              <Button
                variant="text"
                sx={{
                  marginBottom: '0px',
                  color: '#383A49',
                  padding: '0',
                  fontStyle: 'Semibold S',
                }}
                onClick={() => setPayementCancelDialog(false)}
              >
                {tcommon('cancel')}
              </Button>
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
      {children}
    </InsureProductProcessContext.Provider>
  );
};
export default InsureProductProcessContext;
