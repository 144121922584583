export const namespaces = {
  pages: {
    uplaodPage: 'pages.uploadReceipt',
    howPage: 'pages.howitworks',
    processingPage: 'pages.processing',
    quotePropositionPage: 'pages.quoteProposition',
    UserFormPage: 'pages.userFormPage',
    creditCardPage: 'pages.cardPage',
    payementCompletedPage: 'pages.payementCompleted',
    serialNumberPage: 'pages.serialNumber',
    legal: 'pages.legal',
  },
  common: 'common',
};

export const languages = {
  en: 'en',
  fr: 'fr',
  it: 'it',
  sl: 'sl',
  sv: 'sv',
};
