import { ApiServices } from './api-services';
// @ts-ignore
import { environment } from '@env-api/environment';
import { IDataResponse } from '@zenown-insurance/api-interfaces';
import axios from 'axios';

export type ReceiptDataType = Receipt;
export type QuoteDataType = {
  price: number;
  currency: string;
  premium?: boolean;
  uuidItem: string;
  uuidReceipt: string;
  premiumPrice?: number;
};
export type PolicyForm = {
  uid: string;
  product_id: string;
  firstName: string;
  lastName: string;
  email: string;
  sex: number;
  dob: string;
  premium_id: number;
  premium_price: number;
  address: string;
  postal_code: string;
  city: string;
  state: string;
  country: string;
};
export type UserInfo = {
  firstName: string;
  lastName: string;
  email: string;
  dob: string;
  address: string;
  postal_code?: string;
  city?: string;
  state?: string;
  country?: string;
  sex?: number;
};
export type Item = {
  uid: string;
  name: string;
  price: number;
  currency: string;
  brand: string;
  model: string;
};
export type Receipt = {
  uid: string;
  uploadURL?: string;
};
export type Quote = {
  uid: string;
  price: number;
  currency: string;
  startDate?: string;
  endDate?: string;
  premium?: boolean;
  premiumPrice: number;
};

export class AllianzInsuranceService {
  private _apiServices: ApiServices = new ApiServices(environment);

  public set apiServices(apiService: ApiServices) {
    this._apiServices = apiService;
  }
  public get apiServices() {
    return this._apiServices;
  }
  public getPremiumOptions = async (price: number): Promise<any> => {
    return this._apiServices.getRequest('premium', { price });
  };
  public getPictureUploadUrl = async (): Promise<IDataResponse<any>> => {
    /*return new Promise((res) => {
      setTimeout(
        () =>
          res({
            statusCode: 0,
            errorCode: 0,
            error: 'string',
            data: {
              uid: 'photo_f1fq9gg0si',
              uploadURL:
                'https://watch-insurance-stage.s3.amazonaws.com/photo_f1fq9gg0si?X-Amz-Content-Sha256=UNSIGNED-PAYLOAD&X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIA4WVM7S6ZLY66THS7%2F20230507%2Feu-west-3%2Fs3%2Faws4_request&X-Amz-Date=20230507T023814Z&X-Amz-SignedHeaders=host&X-Amz-Expires=1200&X-Amz-Signature=80a0eb8339ab0c0699ca365f4b1439ba0fbb61c2776eee7bde1919f97d8fa117',
            },
          }),
        2000
      );
    });*/
    return this._apiServices.getRequest('receipt/photo/uploadURL', undefined);
  };

  public notifyWithThePhotoUpload = async (
    receiptId: string,
    type: number,
    receipt_id: string
  ): Promise<IDataResponse<any>> => {
    /*return new Promise((res) => {
      setTimeout(
        () =>
          res({
            statusCode: 0,
            errorCode: 0,
            error: 'string',
            data: {},
          }),
        300
      );
    });*/
    return this._apiServices.postRequest('receipt/photo/uploaded', {
      uid: receiptId,
      type: type,
      receipt_uid: receipt_id,
    });
  };

  public getReceipt = async (): Promise<IDataResponse<ReceiptDataType>> => {
    /*  return new Promise((res) => {
      setTimeout(
        () =>
          res({
            statusCode: 0,
            errorCode: 0,
            error: 'string',
            data: {
              uid: 'receipt_sr9qqsw62m',
              uploadURL:
                'https://watch-insurance-stage.s3.amazonaws.com/receipt_sr9qqsw62m?X-Amz-Content-Sha256=UNSIGNED-PAYLOAD&X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIA4WVM7S6ZLY66THS7%2F20230507%2Feu-west-3%2Fs3%2Faws4_request&X-Amz-Date=20230507T023544Z&X-Amz-SignedHeaders=host&X-Amz-Expires=1200&X-Amz-Signature=6d670c5fbbd3bb84b3be60bc7b041d32001272fb00558b7fe85aaa68af8f34af',
            },
          }),
        2000
      );
    });*/
    return this._apiServices.getRequest('receipt/uploadURL', undefined);
  };

  public notifyWithTheUpload = async (
    receiptId: string
  ): Promise<IDataResponse<any>> => {
    /*return new Promise((res) => {
      setTimeout(
        () =>
          res({
            statusCode: 0,
            errorCode: 0,
            error: 'string',
            data: {},
          }),
        300
      );
    });*/
    return this._apiServices.postRequest('receipt/uploaded', {
      uid: receiptId,
    });
  };

  public getProductStatus = async (
    receiptId: string
  ): Promise<IDataResponse<any>> => {
    return this._apiServices.getRequest('status', { uid: receiptId });
  };

  public getQuote = async (
    quoteData: QuoteDataType
  ): Promise<IDataResponse<{ item: Item; receipt: Receipt; quote: Quote }>> => {
    /* return new Promise((res) => {
      setTimeout(
        () =>
          res({
            statusCode: 0,
            errorCode: 0,
            error: 'string',
            data: {
              item: {
                uid: '2bda222a-7a24-4668-8421-39eaa47cc629',
                name: 'Specialized bike',
                price: 5433,
                currency: 'EUR',
                brand: 'Specialized',
                model: 'Enduro',
              },
              receipt: {
                uid: '5d6dd4e3-0e9e-4e7f-a9f8-b9ba6c3cfc35',
                uploadURL:
                  'https://allianz-insurance-test.s3.amazonaws.com/3eb51c78-42c8-4c08-96ef-e19343823900?X-Amz-Content-Sha256=UNSIGNED-PAYLOAD&X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIA4WVM7S6ZA7D4VF6T%2F20220805%2Feu-central-1%2Fs3%2Faws4_request&X-Amz-Date=20220805T125023Z&X-Amz-SignedHeaders=host&X-Amz-Expires=1200&X-Amz-Signature=414a37db77d8d291697ed2a8f1998498b4439eb69b248024cc35a0898692cbad',
              },
              quote: {
                uid: '2bda222a-7a24-4668-8421-39eaa47cc629',
                price: quoteData.price,
                currency: quoteData.currency,
                premium: quoteData.premium,
                premiumPrice: quoteData.premiumPrice || 5,
              },
            },
          }),
        1000
      );
    });*/
    return this._apiServices.postRequest('quote', quoteData);
  };

  public createPolicy = async (form: PolicyForm): Promise<any> => {
    return this._apiServices.postRequest('customer/create', form);
  };

  public uploadFile = async (
    uploadUrl: string,
    file: any,
    options: any
  ): Promise<IDataResponse<any> | any> => {
    return axios.put(uploadUrl, file, {
      ...options,
      headers: { 'Content-Type': 'image/jpeg' },
    });
    return fetch(uploadUrl, {
      headers: { 'Content-Type': 'image/jpeg' },
      body: file,
      method: 'put',
    });
  };
  public getClientSecret = async (form: {
    policy_id: string;
  }): Promise<any> => {
    console.log('form', {
      form,
    });
    return await fetch(
      process.env['NX_INSURANCE_API_URL'] + '/policy/create/payment',
      {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(form),
      }
    ).then((r) => r.json());
  };
  public finalizePayement = async (form: {
    policy_id: string;
  }): Promise<any> => {
    return await this._apiServices.postRequest('policy/paid', form);
  };
  public getSerialUploadUrl = async (product_id: string): Promise<any> => {
    return this._apiServices.postRequest('product/serial/upload', {
      product_id,
    });
  };
  public notifyWthSerialUpload = async (product_id: string): Promise<any> => {
    return this._apiServices.postRequest('product/serial/uploaded', {
      product_id,
    });
  };
  public submitManualSerial = async (input: {
    product_id: string;
    serial: string;
  }): Promise<any> => {
    return this._apiServices.postRequest('product/serial/input', input);
  };
  public payementCompleted = async (policy_id: string): Promise<any> => {
    return await this._apiServices.postRequest('policy/paid', {
      policy_id,
    });
  };
  public stimulateAdminBehavior = async (form: {
    uid: string;
    name: string;
    brand: string;
    price: number;
    currency: string;
    purchase_date: string;
  }): Promise<IDataResponse<any>> => {
    return this._apiServices.postRequest('product', form);
  };
}

export const getInsurancePriceForQuote = (quote: Quote): string => {
  let p = quote.price;
  if (quote.premium) {
    p += quote.premiumPrice;
  }

  return getPriceFormatted(p);
};

export const getPriceFormatted = (
  price: number,
  locale = 'de-CH',
  currency = 'CHF'
): string => {
  return new Intl.NumberFormat(locale, {
    style: 'currency',
    currency: currency,
  }).format(price);
};

export const getCurrencyText = (curr: string): string => {
  const currencies: any = { eur: '€' };

  return currencies[curr.toLowerCase()] || curr;
};
