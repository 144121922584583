import { useContext, useEffect, useRef, useState } from 'react';
import Sheet from 'react-modal-sheet';
import InsureProductProcessContext from '../../contexts/InsureProductProcessContext';

import InfoIcon from '../../assets/insurance/Info-icon.svg';
import './cookies.css';
import {
  Button,
  Checkbox,
  Grid,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
  DialogContent,
  Dialog,
  TextField,
  Link,
  Switch,
  Tooltip,
  IconButton,
} from '@mui/material';
import PrimaryButton from '../primary-button/PrimaryButton';
import { Trans, useTranslation } from 'react-i18next';
import SecondaryButton from '../secondary-button/SecondaryButton';
import ReturnButton from '../return-button/ReturnButton';
import { namespaces } from '@zenown-insurance/i18n-service';
export default function CookiesSheet({ width, open, setOpen }: any) {
  const [step, setStep] = useState(0);
  const [coockies, setCoockies] = useState();
  const [showConfig, setShowConfig] = useState(false);
  const { setShowPolicy } = useContext(InsureProductProcessContext);
  const { t: tupload } = useTranslation(namespaces.pages.uplaodPage);
  const t = (key: string) => tupload(key);
  const [cookiesAnswers, setCookiesAnswers] = useState([
    true,
    false,
    false,
    false,
  ]);
  const answers = [
    { title: t('cookies.config.0.title'), info: t('cookies.config.0.info') },
    { title: t('cookies.config.1.title'), info: t('cookies.config.1.info') },
    { title: t('cookies.config.2.title'), info: t('cookies.config.2.info') },
    { title: t('cookies.config.3.title'), info: t('cookies.config.3.info') },
  ];

  return (
    <>
      <Sheet
        style={{
          maxWidth: '500px',
          margin: '0 auto',
          zIndex: 999,
        }}
        isOpen={open}
        disableDrag={true}
        onClose={() => {
          setOpen(false);
        }}
      >
        <Sheet.Container
          style={{
            maxHeight: showConfig ? '500px' : '400px',
            //padding: '16px',

            maxWidth: Math.min(500, width) + 'px !important',
          }}
        >
          <Sheet.Header style={{ height: '25px' }} />
          <Sheet.Content>
            <div style={{ padding: '16px' }}>
              <Typography
                variant="h3"
                sx={{
                  fontWeight: '800',
                  fontSize: '18px',
                  lineHeight: '24px',
                }}
              >
                {showConfig && (
                  <>
                    <ReturnButton
                      style={{ marginRight: '10px' }}
                      onClick={() => setShowConfig(false)}
                    />
                  </>
                )}
                {t('cookies.title')}
              </Typography>
              <Typography
                variant="h3"
                sx={{
                  fontWeight: '500',
                  fontSize: '14px',
                  lineHeight: '21px',
                  marginTop: '16px',
                  color: '#9CA0AB',
                }}
              >
                {t('cookies.description')}
              </Typography>
              <br />
              <Typography
                variant="h3"
                sx={{
                  fontWeight: '500',
                  fontSize: '14px',
                  lineHeight: '21px',
                  color: '#9CA0AB',
                }}
              >
                <Trans
                  i18nKey={'cookies.footer'}
                  components={{
                    underline: (
                      <Link
                        style={{
                          cursor: 'pointer',
                          color: '#0179FF',
                          textDecoration: 'underline',
                        }}
                        onClick={() => setShowPolicy(true)}
                      >
                        {t('cookies.footer')}
                      </Link>
                    ),
                  }}
                  t={t}
                >
                  {t('cookies.footer')}
                </Trans>
              </Typography>
              {false ? (
                <div style={{ marginTop: '30px' }}>
                  {answers.map((answer, index) => (
                    <>
                      <div
                        style={{
                          marginTop: '20px',
                          display: 'flex',
                          flexDirection: 'row',
                          justifyContent: 'space-between',
                        }}
                      >
                        <div>
                          <Typography
                            sx={{
                              fontSize: '16px',
                              fontWeight: '500',
                              lineHeight: '21px',
                              color: 'black',
                              display: 'inline',
                            }}
                          >
                            {answer.title}
                          </Typography>
                          <Tooltip
                            title={answer.info}
                            style={{
                              zIndex: 9999999,
                              display: 'inline',
                            }}
                          >
                            <IconButton
                              size="small"
                              style={{
                                height: '13.4px',
                                width: '13.4px',
                                marginLeft: '7px',
                              }}
                            >
                              <img
                                src={InfoIcon}
                                style={{ height: '13.4px', width: '13.4px' }}
                              />
                            </IconButton>
                          </Tooltip>
                        </div>
                        <div>
                          <Switch
                            sx={{}}
                            checked={cookiesAnswers[index]}
                            onChange={(e) => {
                              let newAnswers = [...cookiesAnswers];
                              newAnswers[index] = e.target.checked;
                              setCookiesAnswers(newAnswers);
                            }}
                          />
                        </div>
                      </div>
                    </>
                  ))}
                  <PrimaryButton
                    onClick={() => {
                      localStorage.setItem(
                        'cookies_store',
                        JSON.stringify(cookiesAnswers)
                      );
                      setOpen(false);
                    }}
                    sx={{
                      maxHeight: '35px',
                      padding: '5px',
                      marginTop: '20px',
                    }}
                  >
                    <Typography
                      sx={{
                        fontWeight: '500',
                        fontSize: '14px',
                        lineHeight: '19px',
                        color: 'white',
                      }}
                    >
                      {t('cookies.continue')}
                    </Typography>
                  </PrimaryButton>
                </div>
              ) : (
                <>
                  {' '}
                  <div
                    style={{
                      marginTop: '30px',
                      display: 'flex',
                      flexDirection: 'column',
                      justifyContent: 'space-between',
                      gap: '16px',
                      alignItems: 'stretch',
                    }}
                  >
                    <PrimaryButton
                      sx={{ maxHeight: '35px', padding: '5px' }}
                      onClick={() => {
                        localStorage.setItem(
                          'cookies_store',
                          JSON.stringify([true, true, true, true])
                        );
                        setOpen(false);
                      }}
                    >
                      <Typography
                        sx={{
                          fontWeight: '600',
                          fontSize: '14px',
                          lineHeight: '19px',
                          color: 'white',
                        }}
                      >
                        {t('cookies.acceptAll')}
                      </Typography>
                    </PrimaryButton>
                    <PrimaryButton
                      sx={{ padding: '5px', maxHeight: '35px' }}
                      onClick={() => {
                        localStorage.setItem(
                          'cookies_store',
                          JSON.stringify([false, false, false, false])
                        );
                        setOpen(false);
                      }}
                    >
                      <Typography
                        sx={{
                          fontWeight: '600',
                          fontSize: '14px',
                          lineHeight: '19px',
                          color: 'white',
                        }}
                      >
                        {t('cookies.rejectAll')}
                      </Typography>
                    </PrimaryButton>
                  </div>
                  {false && (
                    <SecondaryButton
                      onClick={() => setShowConfig(true)}
                      sx={{
                        backgroundColor: 'white',
                        marginTop: '16px',
                        width: '100%',
                      }}
                    >
                      {t('cookies.manage')}
                    </SecondaryButton>
                  )}
                </>
              )}
            </div>
          </Sheet.Content>
        </Sheet.Container>

        <Sheet.Backdrop />
      </Sheet>
    </>
  );
}
