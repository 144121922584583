import {
  Button,
  Grid,
  IconButton,
  Typography,
  useTheme,
  Dialog,
  TextField,
  DialogContent,
  Stepper,
  Step,
  CircularProgress,
} from '@mui/material';
import { useContext, useEffect, useState } from 'react';
import PrimaryButton from '../../../../components/primary-button/PrimaryButton';
import SwipeableViews from 'react-swipeable-views';
import MountainBike from '../../../../assets/insurance/mountain-bike.png';
import MountainBike2 from '../../../../assets/insurance/mountain-bike@2x.png';
import MountainBike3 from '../../../../assets/insurance/mountain-bike@3x.png';
import CrankUnderSide from '../../../../assets/insurance/crank-underside.png';
import CrankTop from '../../../../assets/insurance/crank-top.png';
import SerialGrab from '../../../../assets/insurance/serial-grab.png';
import SerialGrab2 from '../../../../assets/insurance/serial-grab@2x.png';
import SerialGrab3 from '../../../../assets/insurance/serial-grab@3x.png';
import underBrackets1 from '../../../../assets/insurance/underBracket1.jpg';
import underBrackets12 from '../../../../assets/insurance/underBracket1@2x.jpg';
import underBrackets13 from '../../../../assets/insurance/underBracket1@3x.jpg';
import underBrackets2 from '../../../../assets/insurance/underBracket2.jpg';
import underBrackets22 from '../../../../assets/insurance/underBracket2@2x.jpg';
import underBrackets23 from '../../../../assets/insurance/underBracket2@3x.jpg';
import headTube1 from '../../../../assets/insurance/headTube1.jpg';
import headTube12 from '../../../../assets/insurance/headTube1@2x.jpg';
import headTube13 from '../../../../assets/insurance/headTube1@3x.jpg';
import headTube2 from '../../../../assets/insurance/headTube2.jpg';
import headTube22 from '../../../../assets/insurance/headTube2@2x.jpg';
import headTube23 from '../../../../assets/insurance/headTube2@3x.jpg';
import rear1 from '../../../../assets/insurance/rear1.jpg';
import rear12 from '../../../../assets/insurance/rear1@2x.jpg';
import rear13 from '../../../../assets/insurance/rear1@3x.jpg';
import rear2 from '../../../../assets/insurance/rear2.jpg';
import rear22 from '../../../../assets/insurance/rear2@2x.jpg';
import rear23 from '../../../../assets/insurance/rear2@3x.jpg';
import downtube1 from '../../../../assets/insurance/downtube1.jpg';
import downtube12 from '../../../../assets/insurance/downtube1@2x.jpg';
import downtube13 from '../../../../assets/insurance/downtube1@3x.jpg';
import downtube2 from '../../../../assets/insurance/downtube2.jpg';
import downtube22 from '../../../../assets/insurance/downtube2@2x.jpg';
import downtube23 from '../../../../assets/insurance/downtube2@3x.jpg';
import SeatDownTube from '../../../../assets/insurance/seat-downtube.png';
import SerialSuccess from '../../../../assets/insurance/serial-success.png';
import SerialSuccess2 from '../../../../assets/insurance/serial-success@2x.png';
import SerialSuccess3 from '../../../../assets/insurance/serial-success@3x.png';
import SerialError from '../../../../assets/insurance/serial-error.png';
import SerialError2 from '../../../../assets/insurance/serial-error@2x.png';
import SerialError3 from '../../../../assets/insurance/serial-error@3x.png';
import Resizer from 'react-image-file-resizer';

import RearStays from '../../../../assets/insurance/rear-stays.png';

import HeadSet from '../../../../assets/insurance/headset.png';

import { useHistory } from 'react-router-dom';
import InsureProductProcessContext from '../../../../../lib/contexts/InsureProductProcessContext';
import ReturnButton from '../../../../../lib/components/return-button/ReturnButton';
import { AllianzInsuranceService } from '@zenown-insurance/services/requests';
import { UploadSection } from '../../../../../lib/components/zenown-insurance/UploadSection';
import { useTranslation } from 'react-i18next';
import { namespaces } from '@zenown-insurance/i18n-service';
import { InsuranceLayout } from 'libs/ui/src/lib/components/zenown-insurance';

export function SerialNumberHow(props: any) {
  const [isMobile, setIsMobile] = useState(screen?.width < 1025);

  const [isKeyboardUp, setIsKeyboardUp] = useState(false);
  const [width, setWidth] = useState(375);
  const [showHowIndex, setShowHowIndex] = useState<any>();
  const [serialRejected, setSerialRejected] = useState<any>(undefined);
  const {
    setCurrentProcessStep,
    setProgress,
    item,
    setIsLoading,
    serialFile,
    setSerialFile,
    serialNumber,

    setSerialNumber,
    progress,
    currentProcessStep,
    listenOnSerial,
    serialAccepted,
    setSerialAccepted,
    trackEvent,
  } = useContext(InsureProductProcessContext);

  const history = useHistory();

  const { t } = useTranslation(namespaces.pages.serialNumberPage);

  interface SerialNumberProcessor {
    serial?: string;
    rejection?: string;
    continueTapped: boolean;
  }

  const [uploadSerialUrl, setUploadSerialUrl] = useState<any>();
  useEffect(() => {
    trackEvent({ event: 'Page Visit', action: 'Serial Number' });
    let element: any = document.getElementById('layout-box');
    if (element) {
      let positionInfo: any = element?.getBoundingClientRect();
      setWidth(positionInfo.width);
    }
    setIsLoading(true);

    const savedSerial = localStorage.getItem('serialNumber');

    if (savedSerial) {
      setSerialNumber(savedSerial);
    }

    const embeddedInsuranceService = new AllianzInsuranceService();
    embeddedInsuranceService
      .getSerialUploadUrl(item?.uuid + '')
      .then((res) => {
        console.log('getting serial upload url ', res);
        setUploadSerialUrl(res?.data?.uploadURL);
        setIsLoading(false);
      })
      .catch((err) => {
        console.log('ERR:', err);
        console.log(err);
      });

    listenOnSerial(acceptSerial, declinedSerial);
  }, []);

  const [serialProcessor, setSerialProcessor] = useState<SerialNumberProcessor>(
    { continueTapped: false }
  );

  const resetSerialData = () => {
    console.log('--- removing serial DATA!!!');
    localStorage.removeItem('serialNumber');
    setSerialProcessor({
      continueTapped: false,
    });

    console.log(serialProcessor);
  };

  const serialContinueTap = () => {
    serialProcessor.continueTapped = true;

    console.log('-- Continue Tapped');
    console.log('-- Serial value: ' + serialProcessor.serial);
    console.log('-- Rejection reason: ' + serialProcessor.rejection);

    if (serialProcessor.serial) {
      console.log('-- WE HAVE SERIAL NUMBER');
      continueWithRegisteredSerial();
      return;
    }

    if (serialProcessor.rejection) {
      continueWithRejectedSerial();
      console.log('-- WE HAVE REJECTION');
      return;
    }

    console.log('-- PUSHER EVENT NOT RECEIVED');
  };

  const continueWithRegisteredSerial = () => {
    console.log('---AAAAA----');
    localStorage.setItem('serialAccepted', true + '');
    setSerialAccepted(true);
    setCurrentProcessStep('serial');

    setTimeout(() => {
      setSerialProcessor({ continueTapped: false });
      setSerialAccepted(false);
      setSerialFile(undefined);
      setCurrentProcessStep('legalForm');
    }, 3000);
  };

  const continueWithRejectedSerial = () => {
    setSerialRejected(serialProcessor.rejection || '');
    setCurrentProcessStep('serial');
    setSerialFile(undefined);
    setTimeout(() => {
      setSerialFile(undefined);
    }, 0);
  };

  const acceptSerial = async (data: any) => {
    console.log('Accepted SERIAL');
    trackEvent({
      event: 'Response',
      action: 'serial number accepted',
    });

    const serial = data['serial'] ?? 'Accepted';

    if (serial) {
      console.log('-- SERIAL ACCEPTED WITH VALUE ' + serial);
      serialProcessor.serial = serial;

      console.log(serialProcessor.serial);
      localStorage.setItem('serialNumber', serial);
    }

    if (serialProcessor.continueTapped) {
      continueWithRegisteredSerial();
    } else {
      console.log('-- Continue not yet tapped');
    }
  };

  const declinedSerial = async (data: any) => {
    console.log('Declined SERIAL');
    trackEvent({
      event: 'Response',
      action: 'serial number not recognized',
    });
    serialProcessor.rejection = data['reason'] ?? '';
    serialProcessor.serial = undefined;

    if (serialProcessor.continueTapped) {
      continueWithRejectedSerial();
    }
  };

  const [step, setStep] = useState(0);
  const [howStep, setHowStep] = useState<any>(0);
  const [openDialog, setOpenDialog] = useState(false);
  const [loaded, setLoaded] = useState(false);
  const theme = useTheme();
  const submitManualSerial = () => {
    resetSerialData();

    const embeddedInsuranceService = new AllianzInsuranceService();
    setIsLoading(true);
    embeddedInsuranceService
      .submitManualSerial({
        product_id: item?.uuid + '',
        serial: serialNumber + '',
      })
      .then(() => {
        setIsLoading(false);
        setOpenDialog(false);
        setCurrentProcessStep('legalForm');
      })
      .catch((err) => {
        console.log('ERR:', err);
        console.log(err);
      });
  };

  async function constraintImage(
    d: any,
    quality = 100,
    drop = 2
  ): Promise<any> {
    const resizeFile = async (d: any) =>
      new Promise(async (resolve) => {
        Resizer.imageFileResizer(
          d[0]?.file,
          1000,
          1000,
          'JPEG',
          quality - drop,
          0,
          async (uri: any) => {
            const contentType = uri.split(';base64,')[0]?.replace('data:', '');
            const namesArray = d[0]?.file?.name.split('.');
            namesArray.pop();
            namesArray.push('jpg');

            let newName = 'COMPRESSED-' + namesArray.join('.');
            d = [
              {
                data_url: uri,
                file: new File([uri], newName, { type: contentType }),
              },
            ];
            resolve(d);
          },
          'blob ',
          600,
          600
        );
      });
    if (d && d.length) {
      //TODO: if a condition on size then we resize
      let result: any = await resizeFile(d);
      if (result) {
        return result;
        //TODO: do recursive here till u have udner 3
      }
    }
  }

  const submitSerialFile = async (e: any) => {
    if (e?.length && e[0]?.file) {
      setProgress(1);

      if (e[0]?.file) {
        const embeddedInsuranceService = new AllianzInsuranceService();
        const newFile = await constraintImage(e, 100, 1);
        try {
          let binaryFile: any;
          await fetch(newFile[0].data_url).then(
            async (res) => (binaryFile = await res.blob())
          );
          const options = {
            onUploadProgress: (progressEvent: any) => {
              const { loaded, total } = progressEvent;
              const percent = Math.floor((loaded * 100) / total);

              setProgress(percent);
            },
          };
          await embeddedInsuranceService.uploadFile(
            uploadSerialUrl,
            binaryFile,
            options
          );

          await embeddedInsuranceService.notifyWthSerialUpload(item?.uuid + '');
          //setCurrentProcessStep('legalForm');
          setProgress(undefined);
          setSerialFile(e[0]);
          trackEvent({ event: 'Button click', action: 'Serial photo upload' });

          //here we r gonna simulate the admin behavior by apis
        } catch (err) {
          console.log('ERR:', err);
          setProgress(undefined);
        }
      }
    } else {
      setSerialFile(undefined);
      console.log('ERR:serial file is empty empty file');
    }
  };
  const steps = [
    {
      title: t('underSideCrank'),
      description: t('underSideCrankDescription'),
      image: CrankUnderSide,
      images: [
        [underBrackets1, underBrackets12, underBrackets13],
        [underBrackets2, underBrackets22, underBrackets23],
      ],
      xCord: 132,
      yCord: 152,
    },
    {
      title: t('headset'),
      description: t('headsetDescription'),
      image: HeadSet,
      images: [
        [headTube1, headTube12, headTube13],
        [headTube2, headTube22, headTube23],
      ],
      xCord: 205,
      yCord: 58,
    },
    {
      title: t('rearStays'),
      description: t('rearStaysDescription'),
      image: RearStays,
      images: [
        [rear1, rear12, rear13],
        [rear2, rear22, rear23],
      ],
      xCord: 85,
      yCord: 135,
    },

    {
      title: t('crank'),
      description: t('crankDescription'),
      image: CrankTop,
      images: [
        [downtube1, downtube12, downtube13],
        [downtube2, downtube22, downtube23],
      ],
      xCord: 142,
      yCord: 122,
    },
  ];

  useEffect(() => {
    if (serialFile) setSerialNumber(undefined);
  }, [serialFile, setSerialNumber]);

  useEffect(() => {
    if (serialNumber) setSerialFile(undefined);
  }, [serialNumber, setSerialFile]);

  useEffect(() => {
    setLoaded(false);
    if (showHowIndex >= 0) setHowStep(0);
  }, [showHowIndex]);
  if (showHowIndex >= 0)
    return (
      <div
        style={{
          minHeight: '100vh!important',
          minWidth: '100vw!important',
          position: 'fixed',
          top: 0,
          left: 0,
          height: '100vh',
          width: '100vw',

          background: 'white',
          zIndex: '999',
        }}
      >
        <InsuranceLayout>
          <Grid
            container
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            sx={{ marginTop: '0px' }}
          >
            <Grid item>
              <ReturnButton
                style={{ top: '20px' }}
                onClick={() => setShowHowIndex(undefined)}
                close={true}
              />
            </Grid>
          </Grid>
          <div
            style={{
              marginTop: '60px',
              marginBottom: '30px',
            }}
          >
            <Typography variant="h1">{steps[showHowIndex].title}</Typography>
            <div
              style={{
                position: 'relative',
                marginTop: '5px',
                marginBottom: '15px',
              }}
            ></div>
            <Typography variant="subtitle1">
              {steps[showHowIndex].description}
            </Typography>
          </div>
          <SwipeableViews
            axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
            index={howStep}
            onChangeIndex={(howS: number) => {
              setHowStep(howS);
            }}
            enableMouseEvents
          >
            {steps[showHowIndex].images.map((image, imageIndex) => (
              <div
                style={{
                  //maxHeight: '348px',
                  //minHeight: '348px',
                  width: '100%',
                  marginBottom: '30px',
                }}
              >
                <img
                  onLoad={() => setLoaded(true)}
                  style={{
                    width: '100%',
                    display: loaded ? 'block' : 'none',
                  }}
                  src={image[0]}
                  srcSet={`${image[0]} 1x, ${image[1]} 2x, ${image[2]} 3x`}
                  alt=""
                />

                <div
                  style={{
                    display: loaded ? 'none' : 'block',
                    width: '100%',
                    backgroundColor: '#E1E3E9',
                    minHeight: '403.7px',
                    borderRadius: '12px',
                  }}
                ></div>
              </div>
            ))}
          </SwipeableViews>
          <Stepper
            activeStep={howStep}
            connector={null}
            nonLinear
            sx={{
              background: 'transparent',
              width: 'auto',
              margin: 'auto',
              padding: '0',
              display: 'grid',
              justifyContent: 'center',
              gridTemplateColumns: `repeat(${steps[showHowIndex].images.length}, 42px)`,
              gap: '8px!important',
            }}
          >
            {steps[showHowIndex].images.map((image, imageIndex) => {
              return (
                <Step
                  onClick={() => setHowStep(imageIndex)}
                  sx={
                    imageIndex === howStep
                      ? {
                          height: '3px',
                          minWidth: '42px',
                          maxWidth: '42px',
                          borderRadius: '2px',
                          background: '#383A49',
                          padding: '0',
                        }
                      : {
                          height: '3px',
                          minWidth: '42px',
                          maxWidth: '42px',
                          borderRadius: '2px',
                          background: '#DFE0E5 !important',
                          padding: '0',
                          '&:hover': {
                            background: '#383A49',
                            cursor: 'pointer',
                          },
                          '&:active': {
                            background: '#383A49',
                            cursor: 'pointer',
                          },
                        }
                  }
                ></Step>
              );
            })}
          </Stepper>
        </InsuranceLayout>
      </div>
    );
  else
    return (
      <>
        {' '}
        <Dialog
          PaperProps={{
            sx: {
              marginInline: '15px',
              width: '100%',
              borderRadius: '10px',
              ...(isMobile && isKeyboardUp
                ? {
                    marginBottom: '260px',
                  }
                : {}),
            },
          }}
          sx={{
            borderRadius: '10px',
            overflow: 'hidden',
            marginInline: '0px',
            width: '100%',
          }}
          fullWidth
          maxWidth="sm"
          open={openDialog}
          keepMounted
          //style={{ minWidth: '345px' }}
          onClose={() => setOpenDialog(false)}
        >
          <ReturnButton
            style={{
              position: 'absolute',
              right: 0,
              marginRight: '15px',
              marginTop: '15px',
            }}
            close={true}
            onClick={() => setOpenDialog(false)}
          />
          <DialogContent
            style={{}}
            sx={{
              minHeight: '398px',
              //maxHeight: '398px',
              paddingBottom: '24px',
              borderRadius: '10px',
              overflow: 'hidden',
              height: '100%',
              //minWidth: '345px',
              padding: '0px',
              marginBottom: '0px',
            }}
          >
            <Grid
              container
              direction="column"
              justifyContent="space-between"
              alignItems="center"
              sx={{
                marginTop: '50px',
                //minWidth: '345px',
                width: '100%',
                paddingInline: '24px',
                marginBottom: '0px',
              }}
            >
              <Grid item sx={{ marginBottom: '15px' }}>
                <img
                  src={SerialGrab}
                  srcSet={`${SerialGrab} 1x, ${SerialGrab2} 2x, ${SerialGrab3} 3x`}
                  style={{
                    width: '110px',
                    height: '96px',
                  }}
                  alt=""
                />
              </Grid>
              <Grid item sx={{ marginBottom: '30px' }}>
                <Typography
                  variant="h3"
                  sx={{
                    fontWeight: '600',
                    display: 'flex',
                    justifyContent: 'center',
                    flexDirection: 'column',
                    textAlign: 'center',
                  }}
                >
                  {t('enterSerialNumberPopupTitle')}
                </Typography>
              </Grid>
              <Grid item sx={{ marginBottom: '50px', width: '100%' }}>
                <TextField
                  sx={{ minWidth: '250px', width: '100%' }}
                  defaultValue={serialNumber ? serialNumber : ''}
                  InputProps={{ type: 'search' }}
                  inputProps={{ type: 'search' }}
                  label={t('serialNumber')}
                  fullWidth
                  value={serialNumber ? serialNumber : ''}
                  onClick={() => {
                    setIsKeyboardUp(true);
                    window.setTimeout(
                      () =>
                        document
                          ?.getElementById('serial-dialog-button')
                          ?.scrollIntoView({ behavior: 'smooth' }),
                      0
                    );
                  }}
                  onFocus={() => {
                    setIsKeyboardUp(true);
                    window.setTimeout(
                      () =>
                        document
                          ?.getElementById('serial-dialog-button')
                          ?.scrollIntoView({ behavior: 'smooth' }),
                      0
                    );
                  }}
                  onBlur={() => {
                    console.log('keyboard down');
                    setIsKeyboardUp(false);
                  }}
                  onChange={(event) => {
                    setSerialNumber(event?.target?.value);
                    localStorage.setItem(
                      'serialNumber',
                      event?.target?.value + ''
                    );
                  }}
                />
              </Grid>
              <Grid
                item
                id="serial-dialog-button"
                sx={{ marginBottom: '20px', width: '100%' }}
              >
                <PrimaryButton
                  loadingLogic={true}
                  sx={{ fontStyle: 'Semibold S', width: '100%' }}
                  disabled={!serialNumber}
                  onClick={() => submitManualSerial()}
                >
                  {t('confirm')}
                </PrimaryButton>
              </Grid>
            </Grid>
          </DialogContent>
        </Dialog>
        <Dialog
          open={Boolean(
            currentProcessStep === 'processingSerial' &&
              !serialAccepted &&
              !serialRejected
          )}
          keepMounted
          PaperProps={{
            sx: {
              marginInline: '15px',
              width: '100%',
              borderRadius: '10px',
            },
          }}
          sx={{
            borderRadius: '10px',
            overflow: 'hidden',
            marginInline: '0px',
            width: '100%',
          }}
        >
          <ReturnButton
            style={{
              position: 'absolute',
              right: 0,
              marginRight: '15px',
              marginTop: '15px',
            }}
            close={true}
            onClick={() => {
              setSerialFile(undefined);
              //   setCurrentProcessStep('serial');
            }}
          />
          <DialogContent
            sx={{
              //maxHeight: '398px',
              paddingBottom: '24px',
              overflow: 'hidden',
              borderRadius: '10px',

              //minWidth: '345px',
              padding: '24px',
              marginBottom: '0px',
            }}
          >
            <Grid
              container
              direction="column"
              justifyContent="space-between"
              alignItems="center"
              sx={{
                marginTop: '17px',
                //minWidth: '345px',
                width: '100%',
                //paddingInline: '24px',
                marginBottom: '0px',
              }}
            >
              <Grid item sx={{ marginBottom: '20px' }}>
                <CircularProgress />
              </Grid>
              <Grid item>
                <Typography
                  variant="h3"
                  sx={{
                    marginBottom: '15px',
                    fontWeight: '600',
                    fontSize: '17px',
                    lineHeight: '23.22px',
                    letterSpacing: '-0.3px',

                    textAlign: 'center',
                  }}
                >
                  {t('processing.title')}
                </Typography>
                <Typography
                  color="#9CA0AB"
                  sx={{
                    fontWeight: '500',
                    fontSize: '12px',
                    lineHeight: '18px',
                    letterSpacing: '-0.3px',
                    textAlign: 'center',
                  }}
                >
                  {t('processing.description')}
                </Typography>
              </Grid>
            </Grid>
          </DialogContent>
        </Dialog>
        <Dialog
          PaperProps={{
            sx: {
              marginInline: '15px',
              width: '100%',
              borderRadius: '10px',
            },
          }}
          sx={{
            borderRadius: '10px',
            overflow: 'hidden',
            marginInline: '0px',
            width: '100%',
          }}
          open={serialAccepted}
          keepMounted
          onClose={() => setCurrentProcessStep('legalForm')}
        >
          <ReturnButton
            style={{
              position: 'absolute',
              right: 0,
              marginRight: '15px',
              marginTop: '15px',
            }}
            close={true}
            onClick={() => {
              setCurrentProcessStep('legalForm');
            }}
          />
          <DialogContent
            sx={{
              //maxHeight: '398px',
              paddingBottom: '24px',
              overflow: 'hidden',
              borderRadius: '10px',

              //minWidth: '345px',
              padding: '24px',
              marginBottom: '0px',
            }}
          >
            <Grid
              container
              direction="column"
              justifyContent="space-between"
              alignItems="center"
              sx={{
                marginTop: '30px',
                //minWidth: '345px',
                width: '100%',
                //paddingInline: '24px',
                marginBottom: '0px',
              }}
            >
              <Grid item sx={{ marginBottom: '20px' }}>
                <img
                  src={SerialSuccess}
                  srcSet={`${SerialSuccess} 1x, ${SerialSuccess2} 2x, ${SerialSuccess3} 3x`}
                  style={{
                    width: '72.45px',
                    height: '72.45px',
                  }}
                  alt=""
                />
              </Grid>
              <Grid item>
                <Typography
                  variant="h3"
                  sx={{
                    marginBottom: '15px',
                    fontWeight: '600',
                    fontSize: '17px',
                    lineHeight: '23.22px',
                    letterSpacing: '-0.3px',

                    textAlign: 'center',
                  }}
                >
                  {t('accepted.title')}
                </Typography>
                {false && (
                  <Typography
                    color="#9CA0AB"
                    sx={{
                      fontWeight: '500',
                      fontSize: '12px',
                      lineHeight: '18px',
                      letterSpacing: '-0.3px',
                      textAlign: 'center',
                    }}
                  >
                    {t('accepted.description')}
                  </Typography>
                )}
              </Grid>
            </Grid>
          </DialogContent>
        </Dialog>
        <Dialog
          PaperProps={{
            sx: {
              marginInline: '15px',
              width: '100%',
              borderRadius: '10px',
            },
          }}
          sx={{
            borderRadius: '10px',
            overflow: 'hidden',
            marginInline: '0px',
            width: '100%',
          }}
          open={serialRejected}
          keepMounted
          onClose={() => {
            setSerialFile(undefined);
            setSerialRejected(undefined);
          }}
        >
          <ReturnButton
            style={{
              position: 'absolute',
              right: 0,
              marginRight: '15px',
              marginTop: '15px',
            }}
            close={true}
            onClick={() => {
              setSerialFile(undefined);
              setSerialRejected(undefined);
            }}
          />
          <DialogContent
            sx={{
              //maxHeight: '398px',
              paddingBottom: '24px',
              overflow: 'hidden',
              borderRadius: '10px',

              //minWidth: '345px',
              padding: '24px',
              marginBottom: '0px',
            }}
          >
            <Grid
              container
              direction="column"
              justifyContent="space-between"
              alignItems="center"
              sx={{
                marginTop: '30px',
                //minWidth: '345px',
                width: '100%',
                //paddingInline: '24px',
                marginBottom: '0px',
              }}
            >
              <Grid item sx={{ marginBottom: '20px' }}>
                <img
                  src={SerialError}
                  srcSet={`${SerialError} 1x, ${SerialError2} 2x, ${SerialError3} 3x`}
                  style={{
                    width: '70.24px',
                    height: '70.24px',
                  }}
                  alt=""
                />
              </Grid>
              <Grid item>
                <Typography
                  variant="h3"
                  sx={{
                    marginBottom: '15px',
                    fontWeight: '600',
                    fontSize: '17px',
                    lineHeight: '23.22px',
                    letterSpacing: '-0.3px',

                    textAlign: 'center',
                  }}
                >
                  {t('rejected.title')}
                </Typography>
                <Typography
                  color="#9CA0AB"
                  sx={{
                    fontWeight: '500',
                    fontSize: '12px',
                    lineHeight: '18px',
                    letterSpacing: '-0.3px',
                    textAlign: 'center',
                  }}
                >
                  {serialRejected}
                </Typography>
              </Grid>
            </Grid>
          </DialogContent>
        </Dialog>
        {}
        <Grid
          container
          direction="column"
          alignItems="center"
          justifyContent="flex-start"
          textAlign="start"
        >
          <Grid item>
            <Typography variant="subtitle1" color="#383A49">
              {t('serialPageSubtitle')}
            </Typography>
          </Grid>
          <Grid
            item
            container
            direction="column"
            justifyContent="center"
            alignItems="stretch"
            textAlign={'center'}
            sx={{ marginTop: '28px', padding: '0', minHeight: '28vh' }}
          >
            <Grid
              item
              sx={{
                minHeight: '213px',
                minWidth: '322px',
                maxHeight: '213px',
                maxWidth: '322px',
                position: 'relative',
                width: '322px',
                height: '213px',
                margin: 'auto',
              }}
            >
              {' '}
              <img
                src={MountainBike}
                srcSet={`${MountainBike} 1x, ${MountainBike2} 2x, ${MountainBike3} 3x`}
                style={{
                  width: '322px',
                  height: '213px',
                  position: 'relative',
                }}
                alt="Bike"
              />
              {steps.map((part, index) => (
                <IconButton
                  size="small"
                  onClick={() => setStep(index)}
                  sx={{
                    width: '9.33px !important',
                    height: '9.33px !important',
                    minWidth: '9.33px !important',
                    minHeight: '9.33px !important',
                    maxWidth: '9.33px !important',
                    maxHeight: '9.33px !important',
                    left: part.xCord + 'px',
                    top: part.yCord + 'px',
                    border: '2px solid #FFFFFF',
                    backgroundColor: index === step ? '#0179FF' : '#9EC8F9',
                    position: 'absolute',
                    margin: 'auto',
                    zIndex: '99',
                    boxShadow:
                      index === step
                        ? '0px 0px 0px 1px #0179FF'
                        : '0px 0px 0px 1px #9EC8F9',
                    '&:hover': {
                      backgroundColor: index === step ? '#3378e8' : '#0179FF',
                    },
                    '&:active': {
                      backgroundColor: index === step ? '#3378e8' : '#0179FF',
                    },
                    '&:focus': {
                      backgroundColor: index === step ? '#3378e8' : '#0179FF',
                    },
                  }}
                ></IconButton>
              ))}
            </Grid>
            <Grid
              item
              sx={{
                marginTop: '3.9vh',
                position: 'relative',
                width: '100%',
              }}
            >
              {' '}
              <div
                style={{
                  height: '94px',
                  maxHeight: '94px',
                  //width: '100%',
                  margin: 'auto',
                  //padding: '16px',
                  borderRadius: '10px',
                  border: '1px solid #DFE0E5',
                  alignItems: 'flex-start',
                  //  gap: '10px',
                  background: '#FFFFFF',
                  marginBottom: '23px',
                }}
              >
                <SwipeableViews
                  axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
                  index={step}
                  onChangeIndex={(step: number) => {
                    setStep(step);
                  }}
                  enableMouseEvents
                >
                  {steps.map((part, index) => (
                    <Grid
                      onDoubleClick={() => {
                        setShowHowIndex(index);
                      }}
                      container
                      direction="row"
                      justifyContent="flex-start"
                      sx={{
                        width: '100%',
                        padding: '16px',
                        height: '94px',
                        maxHeight: '94px',
                      }}
                    >
                      <Grid
                        item
                        sx={{
                          width: '62px!important',
                          height: '62px!important',
                          minHeight: '62px!important',
                          minWidth: '62px!important',
                          maxHeight: '62px!important',
                          maxWidth: '62px!important',
                          marginRight: '16px!important',
                        }}
                      >
                        <img
                          src={part.image}
                          style={{
                            width: '62px',
                            height: '62px',
                            minHeight: '62px',
                            minWidth: '62px',
                            maxHeight: '62px',
                            maxWidth: '62px',
                            borderRadius: '10px',
                            marginRight: '16px',
                            backgroundColor: '#E9E9EE',
                          }}
                        />
                      </Grid>
                      <Grid
                        item
                        direction="column"
                        justifyContent="flex-start"
                        alignItems="flex-start"
                      >
                        <Grid item sx={{ marginTop: '2px' }}>
                          {' '}
                          <Typography
                            variant="h3"
                            color="#383A49"
                            sx={{
                              textAlign: 'start',
                              whiteSpace: 'nowrap',
                              fontSize: '17px',
                              fontWeight: '600',
                              lineHeight: '23px',
                              letterSpacing: '-0.3px',
                            }}
                          >
                            {part.title}
                          </Typography>
                        </Grid>
                        <Grid item sx={{ marginTop: '8px' }}>
                          <Typography
                            variant="subtitle1"
                            color="#9CA0AB"
                            sx={{
                              textAlign: 'start',
                              fontSize: '14px',
                              fontWeight: '500',
                              lineHeight: '21px',
                              letterSpacing: '-0.3px',
                            }}
                          >
                            {' '}
                            <Button
                              variant="text"
                              disabled
                              sx={{
                                padding: '0px',
                                height: '21px',
                                textAlign: 'start',
                                fontSize: '14px',
                                fontWeight: '500',
                                lineHeight: '21px',
                                letterSpacing: '-0.3px',
                                marginRight: '3px',
                              }}
                            >
                              {' '}
                              <Typography
                                variant="subtitle1"
                                color="#9CA0AB"
                                sx={{
                                  textAlign: 'start',
                                  fontSize: '14px',
                                  fontWeight: '500',
                                  lineHeight: '21px',
                                  letterSpacing: '-0.3px',
                                }}
                              >
                                {t('notClear') + ' '}
                              </Typography>
                            </Button>
                            <Button
                              variant="text"
                              sx={{
                                padding: '0px',
                                height: '21px',
                                textAlign: 'start',
                                fontSize: '14px',
                                fontWeight: '500',
                                lineHeight: '21px',
                                letterSpacing: '-0.3px',
                              }}
                              onClick={() => {
                                setShowHowIndex(index);
                              }}
                            >
                              <Typography
                                sx={{
                                  color: '#0179FF',
                                  fontSize: '14px',
                                  fontWeight: '500',
                                  lineHeight: '21px',
                                }}
                              >
                                {t('seeMore')}
                              </Typography>
                            </Button>
                          </Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                  ))}
                </SwipeableViews>
              </div>
              <Stepper
                activeStep={step}
                connector={null}
                nonLinear
                sx={{
                  background: 'transparent',
                  width: '100%',
                  margin: 'auto',
                  padding: '0',
                  maxWidth: '152px',
                  display: 'grid',
                  justifyContent: 'space-between',
                  gridTemplateColumns: 'repeat(5, 20%)',
                  gap: '8px!important',
                }}
              >
                {steps.map((label, index) => {
                  return (
                    <Step
                      onClick={() => setStep(index)}
                      sx={
                        index === step
                          ? {
                              height: '3px',
                              minWidth: '24px',
                              maxWidth: '24px',
                              borderRadius: '2px',
                              background: '#383A49',
                              padding: '0',
                            }
                          : {
                              height: '3px',
                              minWidth: '24px',
                              maxWidth: '24px',
                              borderRadius: '2px',
                              background: '#DFE0E5',
                              padding: '0',
                              '&:hover': {
                                background: '#383A49',
                                cursor: 'pointer',
                              },
                              '&:active': {
                                background: '#383A49',
                                cursor: 'pointer',
                              },
                            }
                      }
                    ></Step>
                  );
                })}
              </Stepper>
            </Grid>

            <Grid item sx={{ marginTop: '30px' }}>
              <UploadSection
                key={serialFile ? 'file' : 'no-file'}
                text={t('uploadSerialButtonText')}
                onChange={(e: any) => {
                  if (serialFile && serialAccepted) {
                    return setCurrentProcessStep('legalForm');
                  } else {
                    return submitSerialFile(e);
                  }
                }}
                files={serialFile ? [serialFile] : undefined}
              />
              {serialFile ? (
                <PrimaryButton
                  //loadingLogic={true}
                  onClick={() => {
                    setCurrentProcessStep('processingSerial');
                    serialContinueTap();
                  }}
                  disabled={!serialFile}
                  sx={{
                    fontStyle: 'Semibold S',
                    marginTop: '16px',
                    marginBottom: '15px',
                  }}
                >
                  {t('continue')}
                </PrimaryButton>
              ) : (
                <PrimaryButton
                  onClick={() => {
                    setOpenDialog(true);
                    trackEvent({
                      event: 'Button click',
                      action: 'Enter your serial number manually',
                    });
                    window.setTimeout(
                      () =>
                        document
                          ?.getElementById('serial-dialog-button')
                          ?.scrollIntoView({}),
                      0
                    );
                  }}
                  disabled={Boolean(progress && progress >= 0)}
                  sx={{
                    fontStyle: 'Semibold S',
                    marginTop: '16px',
                    marginBottom: '15px',
                  }}
                >
                  {t('enterSerialManually')}
                </PrimaryButton>
              )}
            </Grid>
          </Grid>
        </Grid>
      </>
    );
}

export default SerialNumberHow;
