import { useTranslation } from 'react-i18next';
import { namespaces } from '@zenown-insurance/i18n-service';
import {
  Avatar,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Paper,
  Typography,
} from '@mui/material';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import Switzerland from '../../../../assets/insurance/switzerland.png';
import Switzerland2 from '../../../../assets/insurance/switzerland@2x.png';
import Switzerland3 from '../../../../assets/insurance/switzerland@3x.png';
import GoldSheild from '../../../../assets/insurance/gold-sheild.png';
import GoldSheild2 from '../../../../assets/insurance/gold-sheild@2x.png';
import GoldSheild3 from '../../../../assets/insurance/gold-sheild@3x.png';
import PlatSheild from '../../../../assets/insurance/plat-sheild.png';
import PlatSheild2 from '../../../../assets/insurance/plat-sheild@2x.png';
import PlatSheild3 from '../../../../assets/insurance/plat-sheild@3x.png';
import InsureProductProcessContext from '../../../../../lib/contexts/InsureProductProcessContext';
import Pusher from 'pusher-js';
import { Item, Quote } from '@zenown-insurance/services/requests';
import PrimaryButton from '../../../../../lib/components/primary-button/PrimaryButton';
import ReturnButton from './../../../../components/return-button/ReturnButton';
export function SurveryScreen(props: any) {
  const {
    setIsLoading,
    quote,
    tProcessing: t,
    setIntialPremium,
    setCurrentProcessStep,
    setSurveyAnswers,
    trackEvent,
    InitProcess,
  } = useContext(InsureProductProcessContext);
  const [showScreen2, setScreen2] = useState(false);
  const suerveyQuestions = [
    {
      question: t('survey.1.question'),
      answerOptions: [t('survey.1.option.1'), t('survey.1.option.2')],
    },
  ];
  const finishSurvey = () => {
    setCurrentProcessStep('watchpictureupload');
    /* if (
      !quote &&
      localStorage.getItem('currentProcessStep') !== 'quoteProposition'
    ) {
      setCurrentProcessStep('processing');
      localStorage.setItem('currentProcessStep', 'processing');
    } else {
      setCurrentProcessStep('quoteProposition');
      localStorage.setItem('currentProcessStep', 'quoteProposition');
    }*/
  };
  const surveryResults = [
    {
      title: t('survey.result.1.title'),
      subTitle: t('survey.result.1.subtitle'),
      pack: t('survey.result.1.pack'),
      submit: t('survey.result.1.submit'),
      onClick: finishSurvey,
    },
    {
      title: t('survey.result.2.title'),
      subTitle: t('survey.result.2.subtitle'),
      pack: t('survey.result.2.pack'),
      submit: t('survey.result.2.submit'),
      onClick: () => setScreen2(true),
    },
  ];
  const [answers, setAnswers] = useState<number[]>([]);

  const [step, setStep] = useState(0);
  const [result, setResult] = useState(0);
  useEffect(() => {
    trackEvent({
      event: 'Page Visit',
      action: 'Question 1: are you a resident in Switzerland',
    });
  }, []);
  useEffect(() => {
    if (answers.length < 1) {
      setStep(answers.length);
    } else {
      surveryResults[answers[0]].onClick();
    }
  }, [answers]);
  if (showScreen2) {
    return (
      <>
        <div
          style={{
            height: '100vh',
            // paddingTop: '128px',

            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            textAlign: 'center',
            flexDirection: 'column',
          }}
        >
          <div
            style={{
              height: '100%',
              width: '100%',
              display: 'flex',
              alignItems: 'center',
              flexDirection: 'column',
              justifyContent: 'center',
            }}
          >
            <div>
              <img
                src={Switzerland}
                srcSet={`${Switzerland} 1x, ${Switzerland2} 2x, ${Switzerland3} 3x`}
                style={{ width: '86px', height: '84px', marginBottom: '22px' }}
              />
              <Typography
                sx={{
                  fontWeight: '600',
                  fontSize: '22px',
                  lineHeight: '28px',
                  color: '#383A49',
                  marginBottom: '16px',
                }}
              >
                {t('survey.screen2.title')}
              </Typography>
            </div>{' '}
          </div>

          <div style={{ width: '100%', marginBottom: '80px' }}>
            <Button
              variant="contained"
              sx={{
                paddingTop: '17px',
                paddingBottom: '17px',
                width: '100%',
                height: '55px',
                textTransform: 'none',
                borderRadius: '10px',
              }}
              onClick={() => {
                setIsLoading(true);

                setTimeout(() => {
                  InitProcess();
                  setIsLoading(false);
                }, 500);
              }}
            >
              <Typography
                sx={{
                  fontWeight: '600',
                  fontSize: '14px',
                  lineHeight: '21px',
                  color: 'white',
                }}
              >
                {t('survey.screen2.Continue')}
              </Typography>
            </Button>
          </div>
        </div>
      </>
    );
  }

  if (answers.length < 1) {
    return (
      <>
        <div
          style={{
            width: '100%',
            height: '100vh',
            paddingTop: '126px',
            textAlign: 'center',
          }}
        >
          <Typography
            sx={{
              fontWeight: '600',
              fontSize: '14px',
              lineHeight: '21px',
              color: '#0179FF',
            }}
          >
            {t('survey.title')}
          </Typography>
          <Typography
            sx={{
              fontWeight: '600',
              fontSize: '22px',
              lineHeight: '28px',
              color: '#383A49',
              marginTop: '24px',
              marginBottom: '77px',
            }}
          >
            {suerveyQuestions[step].question}
          </Typography>
          {suerveyQuestions[step].answerOptions.map((answer, i) => (
            <>
              <Button
                variant="text"
                key={i}
                sx={{
                  paddingTop: '17px',
                  paddingBottom: '17px',
                  width: '100%',
                  height: '55px',
                  textTransform: 'none',
                  border: '1px solid #CDCFD5',
                  marginBottom: i === 0 ? '24px' : '0px',
                }}
                onClick={() => {
                  trackEvent({
                    event: 'Button click',
                    action: i === 0 ? 'Yes' : 'No',
                  });
                  setTimeout(() => setAnswers([...answers, i]), 400);
                }}
              >
                <Typography
                  sx={{
                    fontWeight: '600',
                    fontSize: '14px',
                    lineHeight: '21px',
                    color: '#383A49',
                  }}
                >
                  {answer}
                </Typography>
              </Button>
            </>
          ))}
        </div>
      </>
    );
  } else {
    return <></>;
  }
}

export default SurveryScreen;
